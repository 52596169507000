import React from "react";
import * as Yup from "yup";
import moment from "moment";
import API from "../../../API";
import CRUDTable from "../../../Shared/CRUDTable";
import { Roles } from "../../../../constants";
import * as validationSchemas from "../../../validationSchemas";

const validationSchema = Yup.object({
  name: validationSchemas.readableText.label("Name").required(),
  email: validationSchemas.email.label("Email").required(),
  phone: validationSchemas.phone.label("Phone number").required(),
  pw: validationSchemas.password.label("Password").required(),
});

const Administration = () => {
  return (
    <CRUDTable
      API={API("admin")}
      idExtractor={(data) => data.a_id}
      tableName="Administration"
      config={{
        columns: [
          {
            label: "Name",
            db_name: "name",
            gridOptions: { md: 12 },
          },
          {
            label: "Email",
            db_name: "email",
            gridOptions: { md: 6 },
            inputOptions: {
              type: "email",
            },
          },
          {
            label: "Phone",
            db_name: "phone",
            gridOptions: { md: 6 },
          },
          {
            label: "Password",
            db_name: "pw",
            gridOptions: { md: 6 },
            hideFromTable: true,
            inputOptions: {
              type: "password",
            },
          },
          {
            label: "Role",
            db_name: "role",
            gridOptions: { md: 6 },
            transformFunc: (data) =>
              Object.entries(Roles).find((r) => r[1] === Number(data))[0],
            inputOptions: {
              type: "select",
              options: Object.entries(Roles),
              optionTransformer: (option) => option[0],
              optionValueTransformer: (option) => option[1],
            },
          },
          {
            label: "associated doctor",
            db_name: "doctor_id",
            hideFromTable: true,
            gridOptions: { md: 6 },
            shouldBeRendered: (values) => Number(values.role) === Roles.Doctor,
            inputOptions: {
              type: "select",
              optionsFetchAPI: API("doctor").get(),
              optionTransformer: (option) => option.name,
              optionValueTransformer: (option) => option.doctor_id,
            },
          },
          {
            label: "associated clinic",
            db_name: "clinic_id",
            hideFromTable: true,
            gridOptions: { md: 6 },
            shouldBeRendered: (values) =>
              Number(values.role) === Roles["Center Manager"],
            inputOptions: {
              type: "select",
              optionsFetchAPI: API("clinic").get("active"),
              optionTransformer: (option) => option.name,
              optionValueTransformer: (option) => option.clinic_id,
            },
          },
          {
            label: "Created at",
            db_name: "created_at",
            transformFunc: (data) => moment(data).format("LL"),
            notEditable: true,
          },
          {
            label: "Last updated at",
            db_name: "updated_at",
            transformFunc: (data) => moment(data).format("LL"),
            notEditable: true,
          },
        ],
        formikProps: {
          validationSchema,
        },
      }}
    />
  );
};

export default Administration;
