import React from "react";
import * as Yup from "yup";

import API from "../../../API";
import CRUDTable from "../../../Shared/CRUDTable";
import * as validationSchemas from "../../../validationSchemas";

const validationSchema = Yup.object({
  title: validationSchemas.readableText.required(),
  short_desc: validationSchemas.readableText.label("Short description"),
  package_type: validationSchemas.readableText.label("Package type"),
  composition: validationSchemas.readableText,
  price: validationSchemas.price.required(),
  fake_price: validationSchemas.price.label("Sale price"),
  full_desc: validationSchemas.readableText.label("Full description"),
  instructions: Yup.array()
    .of(validationSchemas.readableText.required())
    .required(),
});

const Packages = () => {
  return (
    <CRUDTable
      API={API("package")}
      idExtractor={(data) => data.package_id}
      tableName="Package"
      filterByConfig={[
        {
          label: "package name",
          value: "title",
        },
        {
          label: "short description",
          value: "short_desc",
        },
        {
          label: "package type",
          value: "package_type",
        },
        {
          label: "composition",
          value: "composition",
        },
      ]}
      config={{
        modalInitialValues: { instructions: [""] },
        columns: [
          {
            label: "package name",
            db_name: "title",
          },
          {
            label: "short description",
            db_name: "short_desc",
            inputOptions: {
              type: "textarea",
              formText: "2 - 3 sentences describing the package",
            },
          },
          {
            label: "package type",
            db_name: "package_type",
            gridOptions: { md: 6 },
          },
          {
            label: "composition",
            db_name: "composition",
            gridOptions: { md: 6 },
          },
          {
            label: "price",
            db_name: "price",
            gridOptions: { md: 6 },
          },
          {
            label: "sale price",
            db_name: "fake_price",
            gridOptions: { md: 6 },
          },
          {
            label: "full description",
            db_name: "full_desc",
            transformFunc: (row) => (
              <p style={{ maxWidth: 100 }}>{row?.full_desc}</p>
            ),
            inputOptions: { type: "textarea" },
          },
          {
            label: "instructions",
            db_name: "instructions",
            transformFunc: (row) => {
              <ul className="p-0">
                {row.map((instruction, idx) => (
                  <li className="text-capitalize" key={idx}>
                    {instruction}
                  </li>
                ))}
              </ul>;
            },
            inputOptions: {
              type: "multi-text",
            },
          },
        ],
        formikProps: { validationSchema },
      }}
    />
  );
};

export default Packages;
