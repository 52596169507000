import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";

import API from "../../../API";
import CRUDTable from "../../../Shared/CRUDTable";
import { useSelector } from "react-redux";
import { Roles } from "../../../../constants";

const Home = () => {
  const admin = useSelector(({ AuthReducer }) => AuthReducer.data ?? {});

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col>
          <CRUDTable
            API={API("order")}
            tableName="Orders"
            idExtractor={(data) => data.order_id}
            getURL="from-admin"
            hideDeleteButton
            hideAddButton
            hideEditButton
            config={{
              columns: [
                {
                  label: "Ordered on",
                  db_name: "created_at",
                  gridOptions: { md: 3 },
                  transformFunc: (data) => moment(data).format("LLL"),
                  inputOptions: {
                    disabled: true,
                    type: "date",
                  },
                },
                {
                  label: "items ordered",
                  db_name: "ordered_items",
                  notEditable: true,
                  transformFunc: (data) => data?.length,
                },
                {
                  label: "total amount",
                  db_name: "ordered_items",
                  gridOptions: { md: 3 },
                  transformFunc: (data) =>
                    data?.reduce((acc, item) => item.price + acc, 0),
                  inputOptions: {
                    disabled: true,
                    getValue: (data) =>
                      data?.reduce((acc, item) => item.price + acc, 0),
                  },
                },
                {
                  label: "discount applied",
                  db_name: "discount_applied",
                  gridOptions: { md: 3 },
                  inputOptions: {
                    disabled: true,
                  },
                },
                {
                  label: "receipt link",
                  db_name: "receipt_link",
                  gridOptions: { md: 3 },
                  transformFunc: (data) =>
                    data &&
                    data !== "" && (
                      <a href={data} target="_blank" rel="noopener noreferrer">
                        Receipt
                      </a>
                    ),
                },
                {
                  label: "status",
                  gridOptions: { md: 4 },
                  transformFuncFromRow: ({
                    delivered_on,
                    shipped_on,
                    packed_on,
                  }) =>
                    delivered_on
                      ? "Delivered on " + moment(delivered_on).format("LL")
                      : shipped_on
                      ? "Shipped on " + moment(shipped_on).format("LL")
                      : packed_on
                      ? "Packed on " + moment(packed_on).format("LL")
                      : "Processing not started",
                  notEditable: true,
                },
              ],
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <CRUDTable
            API={API("lead")}
            idExtractor={(data) => data.lead_id}
            tableName="Leads"
            hideDeleteButton
            hideAddButton
            hideEditButton
            config={{
              columns: [
                {
                  label: "patient name",
                  db_name: "patient_name",
                },
                {
                  label: "mobile number",
                  db_name: "mobile_number",
                },
                {
                  label: "consultancy type",
                  db_name: "consultancy_type",
                },
                {
                  label: "status",
                  db_name: "status",
                },
              ],
            }}
          />
        </Col>
        <Col xs={12} lg={6}>
          <CRUDTable
            API={API("appointment")}
            getURLTransformer={(activePage) =>
              `?pageNum=${activePage}${
                admin.role === Roles.Doctor
                  ? `&doctor_id=${admin.doctor_id}`
                  : ""
              }`
            }
            tableName="Appointments"
            idExtractor={(data) => data.apt_id}
            hideEditButton
            hideDeleteButton
            hideAddButton
            config={{
              columns: [
                {
                  label: "name",
                  db_name: "name",
                },
                {
                  label: "mobile number",
                  db_name: "phone",
                },
                {
                  label: "center",
                  db_name: "clinic_name",
                },
                {
                  label: "doctor name",
                  db_name: "doc_name",
                },
                {
                  label: "date",
                  db_name: "date",
                  transformFunc: (data) => moment(data).format("LL"),
                },
                {
                  label: "time (Online)",
                  db_name: "s_time",
                  shouldBeRendered: (values) => String(values.mode) === "true",
                  transformFuncFromRow: (row) =>
                    row.time
                      ? "-"
                      : moment(row.s_time, "HH:mm:ss").format("LT"),
                },
                {
                  label: "time (Offline)",
                  db_name: "time",
                  transformFuncFromRow: (row) =>
                    row.time ? moment(row.time, "HH:mm").format("LT") : "-",
                },
              ],
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
