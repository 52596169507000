 //const API_HOST = "localhost";
const API_HOST = "api.drpauls-clinic.com";
// export const API_URL = `http://${API_HOST}:3001/`;
// export const MEET_API_URL = `http://${API_HOST}:3002/`;
export const API_URL = `https://${API_HOST}/`;
export const MEET_API_URL = `https://${API_HOST}:3002/`;
// export const API_URL = `http://${API_HOST}/`;
// export const MEET_API_URL = `http://${API_HOST}:3002/`;
// export const PeerServerConfig = {
//   host: "dr-paul-video-call.herokuapp.com",
//   port: 443,
//   secure: true,
// };
// export const PeerServerConfig = {
//   host: "localhost",
//   port: 3002,
//   secure: false,
//   path: "/peer-server",
// };
export const PeerServerConfig = {
  host: API_HOST,
  port: 3002,
  secure: true,
  path: "/peer-server",
};

export const Roles = {
  "Center Manager": 0,
  Marketing: 1,
  "Super Admin": 2,
  Doctor: 3,
  "Pharmacist & Accountant": 4,
};
