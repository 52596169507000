import React from "react";
import { Formik, Form as FormikForm } from "formik";
import FieldWithErrorMsg from "../../../Shared/FieldWithErrorMsg";
import ButtonWithLoading from "../../../Shared/ButtonWithLoading";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import * as validationSchemas from "../../../validationSchemas";

const validationSchema = Yup.object({
  phone: validationSchemas.phone.required("Enter your phone number"),
  password: validationSchemas.text.required("Enter a your password"),
});

const LoginForm = ({ login }) => {
  return (
    <Formik
      initialValues={{
        phone: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={login}
      isInitialValid={false}
    >
      <FormikForm className="d-flex flex-column align-items-center w-100 justify-content-evenly">
        <Form.Group className="w-75 mw6 mb-2">
          <FieldWithErrorMsg name="phone" placeholder="Phone number" />
        </Form.Group>
        <Form.Group className="w-75 mw6 mb-2">
          <FieldWithErrorMsg
            type="password"
            name="password"
            placeholder="Password"
          />
        </Form.Group>
        <ButtonWithLoading className="text-capitalize mt-2">
          Login
        </ButtonWithLoading>
      </FormikForm>
    </Formik>
  );
};

export default LoginForm;
