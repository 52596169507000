import { Formik, getIn, useFormikContext } from "formik";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import FieldWithErrorMsg from "./FieldWithErrorMsg";

const ComposedField = ({
  label,
  name,
  delimiter = " ",
  siblingField,
  containerClass = "",
  ...rest
}) => {
  const { setFieldValue, values, errors, touched, setFieldTouched } =
    useFormikContext();
  const composeValues = (values) => {
    const errors = {};
    setFieldValue(
      name,
      values.composed.reduce((acc, val) => acc + delimiter + val)
    );
    return errors;
  };
  const fieldValue = getIn(values, name);
  return (
    <div className={containerClass}>
      <Formik
        initialValues={{
          composed: (fieldValue && fieldValue.split(delimiter)) || [
            ...new Array(2).fill(""),
          ],
        }}
        validate={composeValues}
        validateOnChange
      >
        {() => (
          <Row role="form" className="px-0 justify-content-around">
            <Col>
              <FieldWithErrorMsg
                label={label}
                name="composed.0"
                onBlur={() => setFieldTouched(name, true)}
                isInvalid={errors[name] && touched[name]}
                renderError={() =>
                  errors[name] &&
                  touched[name] && (
                    <div className="invalid-feedback">{errors[name]}</div>
                  )
                }
                {...rest}
              />
            </Col>
            <Col>
              <FieldWithErrorMsg
                as={Form.Select}
                label={siblingField.label}
                name="composed.1"
                options={siblingField.acceptableValues}
                optionTransformer={(option) =>
                  option.replaceAll("_", " ").toLowerCase()
                }
              />
            </Col>
          </Row>
        )}
      </Formik>
    </div>
  );
};

export default ComposedField;
