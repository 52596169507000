import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";

const Filter = ({
  filterByConfig,
  setFilterBy,
  setSearchKeyword,
  filterBy,
}) => {
  const handleChangeSearch = (e) => {
    setSearchKeyword(e.target.value);
  };

  return (
    <InputGroup>
      <Form.Control onChange={handleChangeSearch} placeholder="Search..." />
      <DropdownButton
        variant="outline-secondary"
        title={filterBy?.label}
        id="input-group-dropdown-2"
        align="end"
      >
        {filterByConfig.map((config, idx) => (
          <Dropdown.Item onClick={() => setFilterBy(config)} key={idx}>
            {config.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </InputGroup>
  );
};

export default Filter;
