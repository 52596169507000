import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, getIn } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import FieldWithErrorMsg from "../../../../../Shared/FieldWithErrorMsg";

const NAME = "name";
const DOSAGE = "dosage";
const POTENCY = "potency";
const INSTRUCTION = "instruction";
const TIME = "time";

const GetTreatments = ({ name }) => {
  return (
    <FieldArray name={name}>
      {({ form, ...arrayHelpers }) => (
        <Col xs={12} className="mt-3">
          <Row>
            <Col className="mb-2 pb-2 border-bottom border-1 d-flex justify-content-between align-items-center">
              <h5 className="text-capitalize">Add medicines</h5>
              <Button
                size="sm"
                onClick={() =>
                  arrayHelpers.push({
                    [NAME]: "",
                    [DOSAGE]: "",
                    [POTENCY]: "",
                    [INSTRUCTION]: "",
                    [TIME]: null,
                  })
                }
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          </Row>
          <Row>
            {getIn(form.values, name).map((treatment, serial) => (
              <Treatment
                key={serial}
                {...{
                  arrayHelpers,
                  serial,
                  treatment,
                  name,
                  form,
                }}
              />
            ))}
          </Row>
        </Col>
      )}
    </FieldArray>
  );
};

export default GetTreatments;

const Treatment = ({ arrayHelpers, treatment, name, serial, form }) => {
  const handleChange = (what, value) => {
    form.setFieldValue(`${name}[${serial}].${what}`, value);
  };

  const handleDelete = () => {
    arrayHelpers.remove(serial);
  };

  return (
    <Row className="d-flex col col-12 mb-3 align-items-center">
      <Col xs={12} md={11} className="d-flex justify-content-evenly">
        <Row>
          <Col xs={12} md={6} xl={6}>
            <FieldWithErrorMsg
              useRawForm
              required
              label="Medicine name"
              className="mb-2"
              value={treatment[NAME]}
              onChange={(e) => handleChange(NAME, e.target.value)}
              placeholder="Name"
              name={`${name}[${serial}].${NAME}`}
            />
          </Col>
          <Col xs={12} md={6}>
            <FieldWithErrorMsg
              useRawForm
              label="Medicine time"
              value={treatment[TIME]}
              className="mb-2"
              onChange={(e) => handleChange(TIME, e.target.value)}
              placeholder="Time"
              type="time"
              name={`${name}[${serial}].${TIME}`}
            />
          </Col>
          <Col xs={12}>
            <FieldWithErrorMsg
              useRawForm
              required
              label={INSTRUCTION}
              value={treatment[INSTRUCTION]}
              className="mb-2"
              onChange={(e) => handleChange(INSTRUCTION, e.target.value)}
              placeholder="Instruction"
              as="textarea"
              name={`${name}[${serial}].${INSTRUCTION}`}
            />
          </Col>
          <Col xs={12} md={6}>
            <FieldWithErrorMsg
              useRawForm
              required
              label={POTENCY}
              value={treatment[POTENCY]}
              className="mb-2"
              onChange={(e) => handleChange(POTENCY, e.target.value)}
              placeholder="Potency"
              name={`${name}[${serial}].${POTENCY}`}
            />
          </Col>
          <Col xs={12} md={6} xl={6}>
            <FieldWithErrorMsg
              useRawForm
              required
              label={DOSAGE}
              value={treatment[DOSAGE]}
              className="mb-2"
              onChange={(e) => handleChange(DOSAGE, e.target.value)}
              placeholder="Dosage"
              name={`${name}[${serial}].${DOSAGE}`}
            />
          </Col>
        </Row>
      </Col>
      <Col className="d-flex justify-content-between">
        <Button variant="danger" size="sm" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Col>
    </Row>
  );
};
