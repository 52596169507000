import React from "react";
import * as Yup from "yup";

import API from "../../../API";
import CRUDTable from "../../../Shared/CRUDTable";
import * as validationSchemas from "../../../validationSchemas";

const validationSchema = Yup.object({
  patient_name: validationSchemas.readableText.label("Patient name").required(),
  mobile_number: validationSchemas.phone.label("Mobile number").required(),
  email: validationSchemas.email.label("Email"),
  age: validationSchemas.age.required(),
  location: validationSchemas.readableText.required(),
  address: validationSchemas.address.required(),
  reference: validationSchemas.readableText.required(),
  status: validationSchemas.readableText.required(),
});

const Leads = () => {
  return (
    <CRUDTable
      API={API("lead")}
      idExtractor={(data) => data.lead_id}
      tableName="Leads"
      config={{
        columns: [
          {
            label: "patient name",
            db_name: "patient_name",
            gridOptions: { md: 6 },
          },
          {
            label: "mobile number",
            db_name: "mobile_number",
            gridOptions: { md: 6 },
          },
          { label: "email", db_name: "email", gridOptions: { md: 6 } },
          { label: "age", db_name: "age", gridOptions: { md: 6 } },
          { label: "location", db_name: "location", gridOptions: { md: 6 } },
          {
            label: "gender",
            db_name: "gender",
            gridOptions: { md: 6 },
            inputOptions: {
              type: "select",
              options: ["Male", "Female", "Other"],
            },
          },
          {
            label: "address",
            db_name: "address",
            inputOptions: { type: "textarea" },
          },
          {
            label: "Consultancy type",
            db_name: "consultancy_type",
            gridOptions: { md: 6 },
            transformFuncFromRow: (row) => row.treatment_type,
            inputOptions: {
              type: "select",
              getOptionsFetchAPI: () => API("treatment-type").get(),
              optionTransformer: (option) => option.name,
              optionValueTransformer: (option) => option.name,
            },
          },
          {
            label: "center",
            db_name: "center",
            gridOptions: { md: 6 },
            inputOptions: {
              type: "select",
              optionsFetchAPI: API("clinic").get("active"),
              optionTransformer: (option) => option.name,
              optionValueTransformer: (option) => option.name,
            },
          },
          { label: "reference", db_name: "reference", gridOptions: { md: 6 } },
          { label: "status", db_name: "status", gridOptions: { md: 6 } },
        ],
        formikProps: {
          validationSchema,
        },
      }}
    />
  );
};

export default Leads;
