/* eslint-disable react/prop-types */
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./index.scss";
import { Button } from "react-bootstrap";
import ActionButton from "../ActionButton";
import DeleteButton from "../DeleteButton";
import DowloadXLSX from "../DowloadXLSX";
import Paginator from "../Paginator";
import Table from "../Table";
import { showNotification } from "../../../utils";
import FilteringAndSorting from "../FilteringAndSorting";
import queryString from "query-string";
import { localeData } from "moment";

const CRUDTable = ({
  pending: pendingFromOutside,
  API,
  config: { columns, modalInitialValues, formikProps, extraFieldsToInitialize },
  tableName,
  idExtractor,
  hasFormData = false,
  hideAddButton = false,
  renderActions: _renderActions,
  renderExtraActions,
  hideEditButton,
  hideDeleteButton,
  modalSize,
  getURL,
  postURL,
  putURL,
  deleteURL,
  getURLTransformer,
  requestBodyTransformer,
  renderExtraStuffWithinModal,
  renderExtraStuffBerforeContentWithinModal,
  filterByConfig,
  sortByConfig,
  isStaticLoad=false,
  hideDownloadButton = true,
}) => {
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [pending, setPending] = useState(false);

  const [userChecked, setUserChecked] = useState([]);

  const [downloadShow, setDownloadShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);

  const fetchTotalPage = () => {
    API.get("page-num")()
      .then((totalPage) => {
        setTotalPage(totalPage);
        setPending(false);
      })
      .catch((err) => {
        setError(true);
        setPending(false);
        console.error(err);
      });
  };

  const fetchData = (options = {}) => {
    setPending(true);

    const { query } = options;
    let q = "?";

    if (query) {
      q += queryString.stringify(query);
    } else {
      q += queryString.stringify({ pageNum: activePage });
    }

    API.get(
      isStaticLoad?"":getURLTransformer ? getURLTransformer(activePage) : (getURL ?? "") + q
    )()
      .then((data) => {
        setData(isStaticLoad?data.data:data);
        setPending(false);
      })
      .catch((err) => {
        setError(true);
        setPending(false);
        console.error(err);
      });
  };

  const handleDelete = (data) => {
    API.delete((deleteURL ? `${deleteURL}/` : "") + idExtractor(data))()
      .then(() => {
        //refetch employee list
        fetchData();
        // give deleted feedback
        showNotification({ msg: `${tableName} deleted` });
      })
      .catch((err) => {
        console.error(err);
        //give error feedback
        showNotification({
          msg: `Error deleting ${tableName}
          `,
          type: "danger",
        });
      });
  };

  //Check Functions

  const setAllChecked = (checked) => {
    checked
      ? setUserChecked(
          userChecked.map((d) => {
            d.checked = true;
            return d;
          })
        )
      : setUserChecked(
          userChecked.map((d) => {
            d.checked = false;
            return d;
          })
        );
  };

  const setChecked = (idx) => {
    setUserChecked(
      userChecked.map((d) => {
        if (idx === d.index) {
          d.checked = !d.checked;
        }
        return d;
      })
    );
  };

  const renderActions = useCallback(
    _renderActions ??
      ((row) => (
        <div className="d-flex justify-content-evenly">
          {/* Edit employee button */}
          {hideEditButton || (
            <ActionButton
              {...{
                ...actionButtonProps,
                mode: "edit",
                API: API.put[hasFormData ? "FormData" : "JSON"](
                  (putURL ? `${putURL}/` : "") + idExtractor(row),
                  {},
                  requestBodyTransformer
                ),
                data: row,
                btn: ({ onClick }) => (
                  <Button
                    title={"Edit " + tableName}
                    size="sm"
                    className="action-btn"
                    {...{ onClick }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                ),
              }}
            />
          )}

          {/* Delete employee button */}
          {hideDeleteButton || (
            <DeleteButton
              handleDelete={() => handleDelete(row)}
              what={`this ${tableName.toLowerCase()}`}
            />
          )}

          {/* Render extra buttons */}
          {renderExtraActions?.(row)}
        </div>
      )),
    [pendingFromOutside, activePage]
  );

  useEffect(() => {
    if(isStaticLoad)
    fetchData()
    else
    fetchTotalPage();
  }, []);

  useEffect(() => {
    if (totalPage) fetchData();
  }, [activePage, totalPage]);

  //CheckBox Triggers
  useEffect(() => {
    if (data) {
      setUserChecked(
        data.map((d, idx) => {
          return { index: idx, checked: false };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    const checked = userChecked.filter((d) => {
      return d.checked === true;
    });
    checked && checked.length ? setDownloadShow(true) : setDownloadShow(false);
  }, [userChecked]);

  const actionButtonProps = useMemo(
    () => ({
      tableName,
      columns,
      refetch: fetchData,
      formikProps,
      modalInitialValues,
      modalSize,
      renderExtraStuffWithinModal,
      renderExtraStuffBerforeContentWithinModal,
      extraFieldsToInitialize,
    }),
    [data]
  );

  return (
    <div className="crud-table-container">
      <div className="d-flex justify-content-between mb-3">
        <h3>{tableName}</h3>
        <div className="d-flex justify-content-between align-items-center">
          {(filterByConfig || sortByConfig) && (
            <FilteringAndSorting
              {...{ filterByConfig, sortByConfig, fetchData }}
            />
          )}
          {downloadShow ? (
            <DowloadXLSX
              csvData={data.filter((d, idx) => {
                return userChecked[idx].checked === true;
              })}
              fileName={tableName}
            />
          ) : null}

          {hideAddButton || (
            <ActionButton
              {...{
                ...actionButtonProps,
                mode: "add",
                API: API.post[hasFormData ? "FormData" : "JSON"](
                  postURL,
                  {},
                  requestBodyTransformer
                ),
                btn: ({ onClick }) => (
                  <Button {...{ onClick }}>
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    <span>New {tableName}</span>
                  </Button>
                ),
              }}
            />
          )}
        </div>
      </div>
      <Table
        className="crud-table"
        {...{
          columns,
          pending: pending || pendingFromOutside,
          data,
          renderActions,
          hideActions: !hideEditButton || !hideAddButton || !hideDeleteButton || !hideDownloadButton,
          userChecked,
        }}
        setAllChecked={setAllChecked}
        setChecked={setChecked}
      />
      <Paginator {...{ totalPage, activePage, setActivePage }} />
    </div>
  );
};

export default CRUDTable;
