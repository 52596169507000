import { useEffect } from "react";
import { useState } from "react";
import useDebounce from "../../CustomHooks/useDebounce";
import Filter from "./Components/Filter";
import Sort from "./Components/Sort";

const FilteringAndSorting = ({ fetchData, filterByConfig, sortByConfig }) => {
  const [filterBy, setFilterBy] = useState(filterByConfig?.[0]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortBy, setSortBy] = useState(sortByConfig?.[0]);
  const [order, setOrder] = useState(0); // 0 => Desc, 1 => Asc

  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);

  useEffect(() => {
    fetchData({
      query: {
        q: debouncedSearchKeyword,
        searchBy: filterBy?.value,
        sortOrder: order,
        sortBy: sortBy ? (sortBy.value === 0 ? "desc" : "asc") : undefined,
      },
    });
  }, [filterBy, debouncedSearchKeyword, order, sortBy]);

  return (
    <div className="d-flex px-3">
      {sortByConfig && (
        <Sort {...{ sortByConfig, setOrder, setSortBy, order, sortBy }} />
      )}
      {filterByConfig && (
        <Filter
          {...{ filterByConfig, setFilterBy, setSearchKeyword, filterBy }}
        />
      )}
    </div>
  );
};

export default FilteringAndSorting;
