import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback,useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { downloadURI, showNotification } from "../../../../../utils";
import API from "../../../../API";
import CRUDTable from "../../../../Shared/CRUDTable";
import * as validationSchemas from "../../../../validationSchemas";
import FormOrPhoto from "./Components/FormOrPhoto";
import { Col, Container, Row } from "react-bootstrap";

const validationSchema = Yup.object({
  srconsultant: validationSchemas.full_name.label("Sr. Consultant"),
  next_apt_date: Yup.date().label("Next appointment date").required(),
  tests: Yup.array().of(validationSchemas.readableText),
  created_at: Yup.date().label("Creation date"),
  attachments: Yup.array().of(validationSchemas.file),
  file: validationSchemas.file.when("prescription_creation_method", {
    is: (method) => method === "file",
    then: validationSchemas.file.required(),
  }),
  treatments: Yup.array().when("prescription_creation_method", {
    is: (method) => method === "manual",
    then: Yup.array()
      .of(
        Yup.object({
          name: validationSchemas.readableText.required(),
          dosage: validationSchemas.readableText.required(),
          potency: validationSchemas.readableText.required(),
          instruction: validationSchemas.readableText.required(),
          time: Yup.mixed(),
        })
      )
      .required(),
  }),
});

const DownloadPrescriptionButton = ({ photo }) => {
  const handleDownload = useCallback(() => {
    const name = photo?.split("/").pop();
    downloadURI(photo, name);
  }, [photo]);

  return (
    <Button
      title="Download"
      size="sm"
      className="action-btn"
      disabled={!photo}
      onClick={handleDownload}
    >
      <FontAwesomeIcon icon={faArrowDown} />
    </Button>
  );
};
const DownloadOfflinePrescriptionButton = ({ Foot_Fall_ID }) => {
  console.log(Foot_Fall_ID)
  const handleDownload = useCallback(() => {
    debugger;
    API("presc").get("/by-user/offline?Foot_Fall_ID="+Foot_Fall_ID,{})()
    .then((data)=>{
      console.log(data)
      if(data.status==1){
        data.data.map(({PDF_Link,Prescription_Name})=>{
          //const name = photo?.split("/").pop();
          downloadURI(PDF_Link, Prescription_Name);
        })
    
      }else{
        showNotification({
          msg: data.data,
          type: "danger",
        });
      }
    }).catch((err)=>{
      showNotification({
        msg: err.message ?? err,
        type: "danger",
      });
    })
    // const name = photo?.split("/").pop();
    // downloadURI(photo, name);
  }, [Foot_Fall_ID]);

  return (
    <Button
      title="Download"
      size="sm"
      className="action-btn"
      disabled={!Foot_Fall_ID}
      onClick={handleDownload}
    >
      <FontAwesomeIcon icon={faArrowDown} />
    </Button>
  );
};
const Prescription = () => {
  const admin = useSelector(({ AuthReducer: { data } }) => data);
  const [Online, setOnline] = useState(true);
  const [Offline, setOffline] = useState(false);
  const [o_mobile, seto_mobile] = useState("");

  return (
    <>
    <div className="crud-table-container">
      <div className="d-flex justify-content-between mb-3">
        {/* <h3>Test</h3> */}
        <div className="d-flex justify-content-between align-items-center">
        <Button
    title={"Edit "}
    size="sm"
    variant={Online?"primary":"outline-primary"}
    className="action-btn"
    style={{margin:'5px'}}
    onClick={()=>{setOnline(!Online);setOffline(false)}}
  >
  Online
  </Button>
  <Button
    title={"Edit "}
    size="sm"
    variant={!Online?"primary":"outline-primary"}
    className="action-btn"
    style={{margin:'5px'}}
    onClick={()=>{setOnline(false)}}
  >
  Offline
  </Button>
        </div>
        
      </div>
      {Online?
      <CRUDTable
      API={API("presc")}
      tableName="Online Prescription"
      idExtractor={(data) => data.presc_id}
      modalSize="xl"
      hideEditButton
      hasFormData
      requestBodyTransformer={(body) => {
        const formData = new FormData();
        for (let key in body) {
          let payload = body[key];

          if (key === "attachments") {
            Object.values(payload).forEach((image) => {
              image !== "" && formData.append("attachments", image);
            });
            continue;
          } else if (["treatments", "tests"].includes(key)) {
            if (key === "treatments")
              payload = payload?.map((item) => {
                if (item.time === "") item.time = null;
                return item;
              });

            formData.append(key, JSON.stringify(payload));
            continue;
          }

          formData.append(key, payload);
        }
        return formData;
      }}
      renderExtraStuffWithinModal={() => (
        <FormOrPhoto formName="treatments" photoName="file" />
      )}
      renderExtraActions={(row) => <DownloadPrescriptionButton {...row} />}
      config={{
        modalInitialValues: {
          treatments: [{ name: "" }],
          tests: [""],
          doctor_id: admin?.doctor_id,
          prescription_creation_method: "manual",
        },
        columns: [
          {
            label: "Consultant",
            db_name: "doctor_id",
            gridOptions: { md: 6 },
            transformFuncFromRow: (row) => row.doc_name,
            inputOptions: {
              type: "select",
              optionsFetchAPI: API("doctor").get(),
              optionTransformer: (option) => option.name,
              optionValueTransformer: (option) => option.doctor_id,
              optionDisbaledStateExtractor: (option) => !option.addr_id,
            },
          },
          {
            label: "patient",
            db_name: "patient_id",
            gridOptions: { md: 6 },
            transformFuncFromRow: (row) => row.u_name ?? row.patient_name,
            inputOptions: {
              type: "select",
              optionsFetchAPI: API("patient").get(),
              optionTransformer: (option) =>
                `${option.name ?? "NA"} - ${option.phone}`,
              optionValueTransformer: (option) => option.patient_id,
              optionDisbaledStateExtractor: (option) => !option.addr_id,
            },
          },
          {
            label: "Sr. Consultant",
            db_name: "srconsultant",
            gridOptions: { md: 6 },
          },
          {
            label: "Next appointment date",
            db_name: "next_apt_date",
            transformFunc: (data) => moment(data).format("LL"),
            gridOptions: { md: 6 },
            defaultValue: moment().format("YYYY-MM-DD"),
            inputOptions: {
              type: "date",
            },
          },
          {
            label: "tests",
            db_name: "tests",
            gridOptions: { md: 6 },
            inputOptions: {
              type: "multi-text",
            },
          },
          {
            label: "Created on",
            db_name: "created_at",
            notEditable: true,
            transformFunc: (data) => moment(data).format("LL"),
            inputOptions: {
              type: "date",
            },
          },
          {
            label: "attachments",
            db_name: "attachments",
            children: [
              {
                label: "image 1",
                db_name: "0",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "file",
                },
              },
              {
                label: "image 2",
                db_name: "1",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "file",
                },
              },
              {
                label: "image 3",
                db_name: "2",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "file",
                },
              },
              {
                label: "image 4",
                db_name: "3",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "file",
                },
              },
            ],
          },
        ],
        formikProps: { validationSchema },
      }}
    />:
    <>
    <Row>
    <Col md={6}>
    <input name="o_mobile" placeholder="Enter patient mobile number" id="o_mobile" class="form-control" value={o_mobile} onChange={(event)=>seto_mobile(event.target.value)}/>
    </Col>
    <Col md={2}>
    <Button
    title={"Edit "}
    size="sm"
    variant={Offline?"primary":"outline-primary"}
    className="action-btn"
    style={{margin:'5px'}}
    onClick={()=>{setOffline(false);setTimeout(()=>{setOffline(true)},100)}}
  >Search</Button>
    </Col>
    </Row> 
   <br></br>
    </>
    }
    {Offline?
      <CRUDTable
      API={API("crm/appointment_details?mobile_no="+o_mobile)}
      tableName="Offline Prescription"
      idExtractor={(data) => data.presc_id}
      modalSize="xl"
      hideEditButton
      hideDeleteButton
      hideAddButton
      isStaticLoad={true}
      hideDownloadButton={false}
      hasFormData
      // requestBodyTransformer={(body) => {
      //   const formData = new FormData();
      //   for (let key in body) {
      //     let payload = body[key];

      //     if (key === "attachments") {
      //       Object.values(payload).forEach((image) => {
      //         image !== "" && formData.append("attachments", image);
      //       });
      //       continue;
      //     } else if (["treatments", "tests"].includes(key)) {
      //       if (key === "treatments")
      //         payload = payload?.map((item) => {
      //           if (item.time === "") item.time = null;
      //           return item;
      //         });

      //       formData.append(key, JSON.stringify(payload));
      //       continue;
      //     }

      //     formData.append(key, payload);
      //   }
      //   return formData;
      // }}
      renderExtraStuffWithinModal={() => (
        <FormOrPhoto formName="treatments" photoName="file" />
      )}
      renderExtraActions={(row) => <DownloadOfflinePrescriptionButton {...row} />}
      config={{
        modalInitialValues: {
          treatments: [{ name: "" }],
          tests: [""],
          doctor_id: admin?.doctor_id,
          prescription_creation_method: "manual",
        },
        columns: [
          {
            label: "Consultant",
            db_name: "Doctor",
            gridOptions: { md: 6 },
            // transformFuncFromRow: (row) => row.doc_name,
            // inputOptions: {
            //   type: "select",
            //   optionsFetchAPI: API("doctor").get(),
            //   optionTransformer: (option) => option.name,
            //   optionValueTransformer: (option) => option.doctor_id,
            //   optionDisbaledStateExtractor: (option) => !option.addr_id,
            // },
          },
          {
            label: "patient",
            db_name: "Patient_Name",
            gridOptions: { md: 6 },
            // transformFuncFromRow: (row) => row.u_name ?? row.patient_name,
            // inputOptions: {
            //   type: "select",
            //   optionsFetchAPI: API("patient").get(),
            //   optionTransformer: (option) =>
            //     `${option.name ?? "NA"} - ${option.phone}`,
            //   optionValueTransformer: (option) => option.patient_id,
            //   optionDisbaledStateExtractor: (option) => !option.addr_id,
            // },
          },
          // {
          //   label: "Sr. Consultant",
          //   db_name: "srconsultant",
          //   gridOptions: { md: 6 },
          // },
          {
            label: "Appointment date",
            db_name: "Appo_Date",
            transformFunc: (data) => moment(data).format("LL"),
            gridOptions: { md: 6 },
            defaultValue: moment().format("YYYY-MM-DD"),
            inputOptions: {
              type: "date",
            },
          },
          {
            label: "Consultancy Type",
            db_name: "Consultancy_Type",
            gridOptions: { md: 6 },
            // inputOptions: {
            //   type: "multi-text",
            // },
          },
          {
            label: "Center_Name",
            db_name: "Cost_Cen_Name",
            notEditable: true,
            // transformFunc: (data) => moment(data).format("LL"),
            // inputOptions: {
            //   type: "date",
            // },
          },
          // {
          //   label: "attachments",
          //   db_name: "attachments",
          //   children: [
          //     {
          //       label: "image 1",
          //       db_name: "0",
          //       gridOptions: { md: 4 },
          //       inputOptions: {
          //         type: "file",
          //       },
          //     },
          //     {
          //       label: "image 2",
          //       db_name: "1",
          //       gridOptions: { md: 4 },
          //       inputOptions: {
          //         type: "file",
          //       },
          //     },
          //     {
          //       label: "image 3",
          //       db_name: "2",
          //       gridOptions: { md: 4 },
          //       inputOptions: {
          //         type: "file",
          //       },
          //     },
          //     {
          //       label: "image 4",
          //       db_name: "3",
          //       gridOptions: { md: 4 },
          //       inputOptions: {
          //         type: "file",
          //       },
          //     },
          //   ],
          // },
        ],
        formikProps: { validationSchema },
      }}
    />:<></>}
    </div>
   
    
    </>
  );
};

export default Prescription;
