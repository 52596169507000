import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Actions } from "../../Redux/rootReducer";
import LoginForm from "./Components/LoginForm";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const login = (values, { setSubmitting }) =>
    dispatch(
      Actions.Auth.login({
        values,
        setSubmitting,
        from: state?.from,
        navigate,
      })
    );

  return (
    <Container fluid>
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col sm={8} md={7} lg={6} xl={5} className="d-flex align-items-center">
          <Card className="mb-0 flex-grow-1 border-0">
            <Card.Header className="bg-white">
              <Card.Title className="text-center">Login</Card.Title>
            </Card.Header>
            <Card.Body className="py-4">
              <LoginForm {...{ login }} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
