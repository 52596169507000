import moment from "moment";
import React from "react";
import * as Yup from "yup";

import API from "../../../API";
import CRUDTable from "../../../Shared/CRUDTable";
import * as validationSchemas from "../../../validationSchemas";

const validationSchema = Yup.object({
  image:validationSchemas.file.required(),
  name: validationSchemas.readableText.required(),
  code: validationSchemas.readableText
    .label("Coupon code")
    .max(15, "Coupon code must be within 15 letters")
    .required(),
  discount: Yup.number().required(),
  discount_type: Yup.bool(),
  max_discount: Yup.number().required(),
  minimum_price: Yup.number()
    .typeError("Order value should be in numbers")
    .required(
      "Please enter a minimum purchase value required to apply this coupon"
    ),
  start_date: Yup.date().required(),
  end_date: Yup.date().required(),
});

const SpecialOffers = () => {
  return (
    <CRUDTable
      API={API("offer")}
      idExtractor={(data) => data.offer_id}
      tableName="Special offer"
      config={{
        columns: [
          {
            label: "image",
            db_name: "image",
            transformFunc: (image) => (
              <img src={image} width="90px" style={{ objectFit: "contain" }} />
            ),
            gridOptions: { md: 6 },
            inputOptions: { type: "file" },
          },
          {
            label: "name",
            db_name: "name",
            inputOptions: {
              formText: "A short note about this offer",
            },
            gridOptions: { md: 6 },
          },
          {
            label: "coupon code",
            db_name: "code",
            inputOptions: {
              formText: "Customers will use this code to appy the coupon",
            },
          },
          {
            label: "discount type",
            db_name: "discount_type",
            transformFunc: (type) => (type ? "Percentage" : "Flat"),
            inputOptions: {
              type: "select",
              options: [true, false],
              optionTransformer: (option) => (option ? "Percentage" : "Flat"),
            },
            gridOptions: { md: 6 },
          },
          {
            label: "discount amount",
            db_name: "discount",
            inputOptions: {
              formText:
                "Based on discount type, enter the amount of discount. i.e for percentage type, you can input 60 for giving 60% off or for flat type, you can enter 100 for flat RS100 off",
            },
            gridOptions: { md: 6 },
          },
          {
            label: "max discount amount",
            db_name: "max_discount",
            inputOptions: {
              formText: "Leave empty in case of flat discount",
            },
            gridOptions: { md: 6 },
          },
          {
            label: "minimum price",
            db_name: "minimum_price",
            inputOptions: {
              formText: "Minimum price to avail this discount",
            },
            gridOptions: { md: 6 },
          },
          {
            label: "start date",
            db_name: "start_date",
            transformFunc: (data) => moment(data).format("LL"),
            gridOptions: { md: 6 },
            inputOptions: { type: "date" },
            formikInitailValueTransformFunc: (data) =>
              moment(data).format("YYYY-MM-DD"),
          },
          {
            label: "end date",
            db_name: "end_date",
            transformFunc: (data) => moment(data).format("LL"),
            gridOptions: { md: 6 },
            inputOptions: { type: "date" },
            formikInitailValueTransformFunc: (data) =>
              moment(data).format("YYYY-MM-DD"),
          },
        ],
        formikProps: { validationSchema },
      }}
      hasFormData
    />
  );
};

export default SpecialOffers;
