import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { showNotification } from "../../../../utils";
import API from "../../../API";
import useFetch from "../../../CustomHooks/useFetch";
import ButtonWithLoading from "../../../Shared/ButtonWithLoading";
import CRUDTable from "../../../Shared/CRUDTable";

const formatNotificationText = (offer) => {
  const discountText = offer.discount_type
    ? `${offer.discount}% discount`
    : `FLAT ₹${offer.discount} OFF`;

  return `Use code ${offer.code.toUpperCase()} and get ${discountText} on any purchase`;
};

const SendNotificationButton = ({ offers, row }) => {
  const [selectedOfferIdx, setSelectedOfferIdx] = useState(0);
  const { callAPI, pending } = useFetch(API("notification").post.JSON(""));

  const handleSendNotification = () => {
    const selectedOffer = offers[selectedOfferIdx];
    callAPI(
      {
        notification: {
          title: selectedOffer.name,
          body: formatNotificationText(selectedOffer),
        },
        u_id: row.u_id,
      },
      () =>
        showNotification({
          msg: "Notification request sent",
        }),
      () =>
        showNotification({
          msg: "Couldn't send notification",
          tyoe: "danger",
        })
    );
  };

  return (
    <div className="d-flex flex-grow-1">
      <Form.Select
        onChange={(e) => setSelectedOfferIdx(Number(e.target.value))}
      >
        {offers?.map((offer, idx) => (
          <option key={idx} value={idx}>
            {offer.name}
          </option>
        ))}
      </Form.Select>
      <ButtonWithLoading loading={pending} onClick={handleSendNotification}>
        Send
      </ButtonWithLoading>
    </div>
  );
};

const Marketing = () => {
  const { data: offers, callAPI, pending } = useFetch(API("offer").get());

  const renderNotificationButton = (row) => (
    <SendNotificationButton {...{ offers, row }} />
  );

  useEffect(() => {
    callAPI();
  }, []);

  return (
    <CRUDTable
      pending={pending}
      API={API("user")}
      tableName="Marketing"
      config={{
        columns: [
          {
            label: "patient name",
            db_name: "u_name",
            gridOptions: { md: 6 },
          },
          {
            label: "mobile number",
            db_name: "u_phone",
            gridOptions: { md: 6 },
          },
          { label: "email", db_name: "u_email", gridOptions: { md: 6 } },
        ],
      }}
      renderActions={renderNotificationButton}
      hideAddButton
    />
  );
};

export default Marketing;
