import { useCallback, useState } from "react";

/**
 *
 * @param {()=>Promise} apiCall
 * @param {Array<any>} dependencies
 * @returns {{pending:boolean, error:string, data:any, callAPI:Function}}
 */

export default function useFetch(apiCall, dependencies = []) {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const callAPI = useCallback(
    (body, callback, errorCallback, _apiCall) => {
      setPending(true);
      (_apiCall ?? apiCall)(body)
        .then((data) => {
          setData(data);
          setPending(false);
          callback();
        })
        .catch((err) => {
          setPending(false);
          setError(err);
          errorCallback(err);
        });
    },
    [...dependencies]
  );

  return { pending, error, data, callAPI };
}
