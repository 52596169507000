/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Actions } from "../Redux/rootReducer";

const Notification = ({ msg, caption, type = "success", action, btnText }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const popNotification = () => dispatch(Actions.Notification.remove());
    const timeout = setTimeout(popNotification, 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="p-2 rounded-3 position-relative bg-white mb-2">
      <div
        className={`position-absolute start-0 top-0 bottom-0 pe-2 bg-${type}`}
        style={{ borderRadius: "0.3rem 0 0 0.3rem" }}
      />
      <div className="ms-2">
        <p
          className={`text-capitalize mb-1 ${
            !caption ? "py-2" : ""
          } text-${type}`}
        >
          {msg}
        </p>
        {caption && <small className="me-2">{caption}</small>}
        {action && (
          <Button color={type} onClick={action} size="sm">
            {btnText}
          </Button>
        )}
      </div>
    </div>
  );
};

const NotificationService = () => {
  const notificationQueue = useSelector(
    (state) => state.NotificationReducer.queue
  );

  return (
    <div
      className="position-fixed end-0 me-2"
      style={{ top: "40px", zIndex: 10000 }}
    >
      {notificationQueue.map((notification, idx) => (
        <Notification key={idx} {...notification} />
      ))}
    </div>
  );
};

export default NotificationService;
