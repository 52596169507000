import React, { useCallback, useState } from "react";
import moment from "moment";

import API from "../../../../API";
import CRUDTable from "../../../../Shared/CRUDTable";
import OrderItems from "./Components/OrderItems";
import { downloadURI } from "../../../../../utils";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faSpinner } from "@fortawesome/free-solid-svg-icons";

const DownloadOrderLabelButton = ({ orderlabel_link, order_id }) => {
  const [pending, setPending] = useState(false);

  const handleDownload = useCallback(() => {
    setPending(true);
    if (!orderlabel_link) {
      API("order")
        .get(`order-label/${order_id}`)()
        .then(({ link }) => {
          const name = link.split("/").pop();
          downloadURI(link, name);
          setPending(false);
        });
    } else {
      const name = orderlabel_link.split("/").pop();
      downloadURI(orderlabel_link, name);
      setPending(false);
    }
  }, [orderlabel_link]);

  return (
    <Button
      title="Download order label"
      size="sm"
      className="action-btn"
      onClick={handleDownload}
    >
      {pending ? (
        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
      ) : (
        <FontAwesomeIcon icon={faArrowDown} />
      )}
    </Button>
  );
};

const Order = () => {
  return (
    <CRUDTable
      API={API("order")}
      tableName="Orders"
      idExtractor={(data) => data.order_id}
      modalSize="xl"
      getURL="from-admin"
      postURL="from-admin"
      putURL="from-admin"
      hideDeleteButton
      hideAddButton
      renderExtraStuffBerforeContentWithinModal={() => <OrderItems />}
      renderExtraActions={(row) => <DownloadOrderLabelButton {...row} />}
      config={{
        columns: [
          {
            label: "delivery address",
            db_name: "address",
            inputOptions: {
              disabled: true,
              className: "mb-4",
            },
            children: [
              {
                label: "name",
                db_name: "name",
                gridOptions: { md: 4 },
              },
              {
                label: "email",
                db_name: "email",
                gridOptions: { md: 4 },
              },
              {
                label: "phone",
                db_name: "phone",
                gridOptions: { md: 4 },
              },
              {
                label: "alternate phone",
                db_name: "alt_phone",
                gridOptions: { md: 4 },
              },
              {
                label: "street",
                db_name: "street",
                gridOptions: { md: 4 },
              },
              {
                label: "landmark",
                db_name: "landmark",
                gridOptions: { md: 4 },
              },
              {
                label: "city",
                db_name: "city",
                gridOptions: { md: 4 },
              },
              {
                label: "state",
                db_name: "state",
                gridOptions: { md: 4 },
              },
              {
                label: "pincode",
                db_name: "pincode",
                gridOptions: { md: 4 },
              },
            ],
          },
          {
            label: "Ordered on",
            db_name: "created_at",
            gridOptions: { md: 3 },
            transformFunc: (data) => moment(data).format("LLL"),
            formikInitailValueTransformFunc: (value) =>
              value && moment(value).format("YYYY-MM-DD"),
            inputOptions: {
              disabled: true,
              type: "date",
            },
          },
          {
            label: "items ordered",
            db_name: "ordered_items",
            notEditable: true,
            transformFunc: (data) => data?.length,
          },
          {
            label: "total amount",
            db_name: "ordered_items",
            gridOptions: { md: 3 },
            transformFunc: (data) =>
              data?.reduce((acc, item) => item.price + acc, 0),
            inputOptions: {
              disabled: true,
              getValue: (data) =>
                data?.reduce((acc, item) => item.price + acc, 0),
            },
          },
          {
            label: "discount applied",
            db_name: "discount_applied",
            gridOptions: { md: 3 },
            inputOptions: {
              disabled: true,
            },
          },
          {
            label: "receipt link",
            db_name: "receipt_link",
            gridOptions: { md: 3 },
            transformFunc: (data) =>
              data &&
              data !== "" && (
                <a href={data} target="_blank" rel="noopener noreferrer">
                  Receipt
                </a>
              ),
          },
          {
            label: "DOB",
            db_name: "dob",
            transformFunc: (data) => data && moment(data).format("LL"),
            gridOptions: { md: 3 },
            inputOptions: {
              disabled: true,
            },
          },
          {
            label: "packed on",
            db_name: "packed_on",
            transformFunc: (data) => data && moment(data).format("LL"),
            formikInitailValueTransformFunc: (value) =>
              value && moment(value).format("YYYY-MM-DD"),
            gridOptions: { md: 4 },
            inputOptions: {
              type: "date",
            },
          },
          {
            label: "shipped on",
            db_name: "shipped_on",
            transformFunc: (data) => data && moment(data).format("LL"),
            formikInitailValueTransformFunc: (value) =>
              value && moment(value).format("YYYY-MM-DD"),
            gridOptions: { md: 4 },
            inputOptions: {
              type: "date",
            },
          },
          {
            label: "delivered on",
            db_name: "delivered_on",
            transformFunc: (data) => data && moment(data).format("LL"),
            formikInitailValueTransformFunc: (value) =>
              value && moment(value).format("YYYY-MM-DD"),
            gridOptions: { md: 4 },
            inputOptions: {
              type: "date",
            },
          },
        ],
      }}
    />
  );
};

export default Order;
