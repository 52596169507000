import React from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import routes from "../../routes";
import Header from "../../Shared/Header";
import Sidebar from "../../Shared/Sidebar";
import "./index.scss";
import AddBanner from "./Views/AddBanner";
import Administration from "./Views/Administration";
import Appointments from "./Views/Appointments";
import BillAndPayments from "./Views/BillAndPayments";
import Doctors from "./Views/Doctors";
import Home from "./Views/Home";
import Leads from "./Views/Leads";
import Marketing from "./Views/Marketing";
import Meeting from "./Views/Meeting";
import Order from "./Views/Order";
import Packages from "./Views/Packages";
import Patients from "./Views/Patients";
import Prescription from "./Views/Prescription";
import Products from "./Views/Products";
import SpecialOffers from "./Views/SpecialOffers";
import Videos from "./Views/Videos";

const Dashboard = () => {
  return (
    <Container fluid className="main-wrapper d-flex p-0">
      <Sidebar routes={Object.values(routes.Dashboard.views)} />
      <div className="content-wrapper flex-grow-1 min-vh-100">
        <Header />
        <Container fluid className="content px-3">
          <Outlet />
        </Container>
      </div>
    </Container>
  );
};

export default Dashboard;
export const DashboardViews = {
  Leads,
  SpecialOffers,
  Marketing,
  Meeting,
  Appointments,
  AddBanner,
  Patients,
  Packages,
  Doctors,
  Prescription,
  Products,
  BillAndPayments,
  Home,
  Videos,
  Administration,
  Order,
};
