import { createSlice } from "@reduxjs/toolkit";

const { reducer: NotificationReducer, actions } = createSlice({
  name: "notification",
  initialState: {
    queue: [],
  },
  reducers: {
    add: (state, action) => {
      state.queue.unshift(action.payload);
    },
    remove: (state) => {
      state.queue.pop();
    },
  },
});

export const Notification = { ...actions };
export default NotificationReducer;
