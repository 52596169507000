import React from "react";
import "./index.scss";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShowForRole from "../ShowForRole";

const Sidebar = ({ routes }) => {
  const location = useLocation();

  // verifies if routeName is the one active
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    <div className="sidebar-wrapper">
      <div className="logo d-flex align-items-center">
        <h4>Dr Paul Admin</h4>
      </div>
      <div className="sidebar">
        <Nav className="flex-column">
          {routes.map(
            (route, key) =>
              route.hideFromSidebar || (
                <ShowForRole roles={route.roles}>
                  <li className={activeRoute(route.path)} key={key}>
                    <NavLink
                      to={route.path}
                      className="nav-link d-flex align-items-center"
                    >
                      <FontAwesomeIcon icon={route.icon} />
                      <p className="mb-0 ms-2">{route.name}</p>
                    </NavLink>
                  </li>
                </ShowForRole>
              )
          )}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
