import * as Yup from "yup";

export const phone = Yup.string()
  .trim()
  .matches(
    /^[+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im,
    "Please enter a valid phone number"
  );
export const age = Yup.number().integer().max(200).min(1);
export const photo = Yup.mixed();
export const full_name = Yup.string()
  .trim()
  .matches(
    /^([A-Z.]+ )?[A-Z]+ [A-Z]+( [A-Z]+)?$/gi,
    "Please enter full name. Also it should only contain letters."
  );
export const business_name = Yup.string()
  .trim()
  .max(15)
  .matches(/^( [a-z]+|[a-z]+)+$/gi, {
    message: "Business name should only contain letters",
  })
  .required("Please enter the name of your business or store");
export const username = Yup.string()
  .max(15)
  .matches(/^[0-9a-zA-Z_-]+$/g, {
    message: "Username should only contain letters, numbers, _ and -",
  })
  .required(
    "Please enter a username hat customers will use to find your store"
  );
export const email = Yup.string()
  .trim()
  .email("Please enter a valid email address");
export const address = Yup.string().matches(/^([a-z0-9 ,/])*$/gi, {
  message:
    "Address can only contain letters, numbers, comma(,) and forward slash(/)",
});
export const city = Yup.string().matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, {
  message: "City name can only contain letters",
});
export const state = Yup.string().matches(
  /^[a-zA-Z]+(?:[\s-][a-zA-Z0-9]+)*(?:[\s-][a-zA-Z0-9]+)*$/,
  {
    message: "State name can only contain letters, numbers and space",
  }
);
export const country = Yup.string().matches(
  /^[a-z]+((\s?&)|-)?(\s?[a-z]+)*$/gi,
  {
    message: "County name can only contain letters, &, -",
  }
);
export const zip = Yup.string()
  .max(6)
  .matches(/^[0-9]{6}(?:-[0-9]{4})?$/gi, {
    message: "Please enter a valid zip code",
  });
export const price = Yup.string().matches(/^[0-9.]+$/, {
  message: "Price must be in digits",
});
export const readableText = Yup.string().matches(
  /^[a-z0-9 &%/:.,()\-'`]*$/gim,
  {
    message:
      "Please only use letters, numbers and &%/:.()-'` for special characters to keep it more readable to the customers",
  }
);
export const integer = Yup.number().integer();
export const nonUserDefinedString = Yup.string().required();
export const url = Yup.string().url();
export const text = Yup.string();
export const password = text;
export const positiveNumber = Yup.number().min(0);
export const file = Yup.mixed();
