import React from "react";

const Item = ({ item }) => {
  return (
    <tr>
      <td>
        <img width="90" alt={item.name} src={item.photo} />
      </td>
      <td>{item.name}</td>
      <td>{item.qty}</td>
      <td>{item.price}</td>
    </tr>
  );
};

export default Item;
