import { Button, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";

const Sort = ({ sortByConfig, setOrder, setSortBy, order, sortBy }) => {
  const handleToggleOrder = () => {
    setOrder((currentOrder) => (currentOrder === 0 ? 1 : 0));
  };

  return (
    <InputGroup>
      <DropdownButton
        variant="outline-secondary"
        title={sortBy.label}
        id="input-group-dropdown-1"
      >
        {sortByConfig.map((config, idx) => (
          <Dropdown.Item onClick={() => setSortBy(config)} key={idx}>
            {config.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <Button onClick={handleToggleOrder}>
        {order === 0 ? "Descending" : "Ascending"}
      </Button>
    </InputGroup>
  );
};

export default Sort;
