import { weekdays } from "moment";
import { store } from ".";
import { Actions } from "./App/Redux/rootReducer";
import { Roles } from "./constants";

export const showNotification = (config) =>
  store.dispatch(Actions.Notification.add(config));

export const getAccessToken = () => store.getState().AuthReducer.token;

export const saveInLocalStorage = (data) =>
  localStorage.setItem("data", JSON.stringify(data));

export const retrieveFromLocalStorage = () => {
  const data = localStorage.getItem("data");

  //data may be undefined
  return data ? JSON.parse(data) : null;
};

export const daysInWeek = weekdays();

export const randomString = () => Math.random().toString(24);

export function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.target = "_blank";
  link.click();
}

export function isAllowed(roles, role) {
  return (
    role === Roles["Super Admin"] ||
    !roles ||
    roles.length === 0 ||
    roles.includes(role)
  );
}

export function isEmpty(objectOrArray) {
  if (Array.isArray(objectOrArray)) {
    if (objectOrArray.length === 0) return true;
    else {
      const firstElement = objectOrArray[0];

      if (typeof firstElement === "object") {
        if (Object.keys(firstElement).length === 0) return true;
      }
    }
  }

  return false;
}
