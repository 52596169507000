import React from "react";
import { Formik, Form as FormikForm } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import FieldWithErrorMsg from "./FieldWithErrorMsg";
import ComposedField from "./ComposedField";

const DataForm = ({
  initialValues,
  columns,
  handleSubmit,
  button,
  formikProps,
  renderExtraStuffWithinModal: renderExtraStuffAfterContentWithinModal,
  renderExtraStuffBerforeContentWithinModal,
  mode,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      {...formikProps}
    >
      {({ values }) => (
        <FormikForm>
          <Container>
            <Row>
              {renderExtraStuffBerforeContentWithinModal?.({
                initialValues,
                validationSchema: formikProps?.validationSchema,
                columns,
                handleSubmit,
                button,
                formikProps,
                renderExtraStuffWithinModal:
                  renderExtraStuffBerforeContentWithinModal,
                mode,
              })}
            </Row>
            <Row>
              {columns.map(
                (field, idx) =>
                  field.notEditable ||
                  (field.shouldBeRendered && !field.shouldBeRendered(values)) ||
                  (field.children ? (
                    <Row
                      className={field.inputOptions?.className}
                      disabled={field.inputOptions?.disabled}
                      as="fieldset"
                    >
                      <h5
                        key={idx}
                        className="text-capitalize mb-2 mt-3 pb-2 border-bottom border-1"
                      >
                        {field.label}
                      </h5>
                      {field.children.map((childField, idx) =>
                        renderField(
                          childField,
                          formikProps?.validationSchema,
                          idx,
                          field
                        )
                      )}
                    </Row>
                  ) : (
                    renderField(field, formikProps?.validationSchema, idx)
                  ))
              )}
            </Row>
            <Row>
              {renderExtraStuffAfterContentWithinModal?.({
                initialValues,
                validationSchema: formikProps?.validationSchema,
                columns,
                handleSubmit,
                button,
                formikProps,
                renderExtraStuffWithinModal:
                  renderExtraStuffAfterContentWithinModal,
                mode,
              })}
            </Row>
          </Container>
          <Container>
            <Row>
              <Col className="d-flex align-items-center">
                <p className="text-danger mb-0">* indicates a required field</p>
              </Col>
              <Col>{button}</Col>
            </Row>
          </Container>
        </FormikForm>
      )}
    </Formik>
  );
};

export default DataForm;

const renderField = (field, validationSchema, idx, parentField) => (
  <Col key={idx} {...(field.gridOptions ?? { xs: 12 })} className="mb-2">
    {field.siblingField ? (
      <ComposedField
        name={
          parentField
            ? `${parentField.db_name}.${field.db_name}`
            : field.db_name
        }
        label={field.label}
        siblingField={field.siblingField}
        required={
          (parentField
            ? validationSchema?.fields?.[parentField.db_name]?.fields?.[
                field.db_name
              ]
            : validationSchema?.fields?.[field.db_name]
          )?.exclusiveTests?.required ?? false
        }
      />
    ) : (
      <FieldWithErrorMsg
        label={field.label}
        name={
          parentField
            ? `${parentField.db_name}.${field.db_name}`
            : field.db_name
        }
        required={
          (parentField
            ? validationSchema?.fields?.[parentField.db_name]?.fields?.[
                field.db_name
              ]
            : validationSchema?.fields?.[field.db_name]
          )?.exclusiveTests?.required ?? false
        }
        {...(field.inputOptions || {})}
      />
    )}
  </Col>
);
