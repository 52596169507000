import React, { useEffect, useState } from "react";
import "./index.css";

import { Row } from "react-bootstrap";
import BannerImage from "./Components/BannerImage";
import API from "../../../../API";

export default function AddBanner() {
  const [carousels, setCarousels] = useState();

  const fetchCarousels = () =>
    API("carousel").get("")().then(setCarousels).catch(console.error);

  useEffect(() => {
    fetchCarousels();
  }, []);

  return (
    <div className="addBanner__info">
      <div className="addBanner__info__header">
        <h3>Add Banner</h3>
      </div>
      {carousels?.map((carousel, idx) => (
        <Carousel key={idx} {...{ carousel }} refresh={fetchCarousels} />
      ))}
    </div>
  );
}

const Carousel = ({
  carousel: { slides, description, carousel_id },
  refresh,
}) => {
  return (
    <form>
      <div className="addBanner__info__inputs">
        <div className="addBanner__section addBanner__top__carousel">
          <div className="addBanner__sub__header">
            <h4>{description}</h4>
          </div>

          <Row
            style={{ margin: "10px", marginBottom: "0" }}
            className="adddoctor__info__input__row"
          >
            {slides.map((slide, idx) => (
              <BannerImage
                {...{ refresh, slide }}
                key={idx}
                label={`c-${carousel_id}-s-${slide.slide_id}`}
                className="add_banner_input"
              />
            ))}
          </Row>
        </div>
      </div>
    </form>
  );
};
