import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function AuthRequired({ children }) {
  const isAuthed = useSelector(({ AuthReducer }) => !!AuthReducer.token);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthed) {
      navigate("/login", { state: { from: location.pathname }, replace: true });
    }
  }, [isAuthed]);

  return children;
}
