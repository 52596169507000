import { faCloudUploadAlt, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";
import { showNotification } from "../../../../../../../utils";
import API from "../../../../../../API";
import DeleteButton from "../../../../../../Shared/DeleteButton";
import "./index.css";

export default function BannerImage({
  slide: { photo, slide_id },
  className,
  label,
  refresh,
}) {
  const [image, setImage] = useState("");
  const [editing, setEditing] = useState(false);

  const ImageChangeHandler = (e) => {
    const image = e.target.files[0];
    setImage(image);
    setEditing(true);
  };

  const handleDelete = () => {
    API("carousel")
      .put.JSON("slide/" + slide_id)({ photo: null })
      .then(() => {
        setImage("");
        refresh();
      })
      .catch((err) => {
        console.error(err);
        showNotification({ msg: "Couldn't delete slide", type: "danger" });
      });
  };

  const handleEditImage = () => {
    API("carousel")
      .put.FormData("slide/" + slide_id)({ photo: image })
      .then(() => {
        setImage("");
        setEditing(false);
        refresh();
      })
      .catch((err) => {
        console.error(err);
        showNotification({ msg: "Couldn't edit slide", type: "danger" });
      });
  };

  const handleCancel = () => {
    setEditing(false);
    setImage("");
  };

  return (
    <Col xs="12" md="4" xxl="3" className={`input__image__div ${className}`}>
      <div className="input__image__div__wrapper">
        {photo || image ? (
          <div className="input__image">
            <img src={image ? URL.createObjectURL(image) : photo} alt="" />
          </div>
        ) : (
          <label htmlFor={label} style={{ cursor: "pointer" }}>
            <div className="input__image__content">
              <div className="input__image__content__icon">
                <FontAwesomeIcon icon={faCloudUploadAlt} />
              </div>
              <p>No files chosen yet! </p>
            </div>
          </label>
        )}
        {photo && !editing && !image && (
          <div className="input__image__control position-absolute absolute--fill align-items-center justify-content-evenly">
            <label htmlFor={label} style={{ cursor: "pointer" }}>
              <div className="btn btn-primary">
                <FontAwesomeIcon icon={faPen} />
              </div>
            </label>
            <DeleteButton size="md" handleDelete={handleDelete} what="slide" />
          </div>
        )}
      </div>
      {editing && (
        <div className="mt-2 d-flex justify-content-evenly">
          <Button variant="light" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleEditImage}>
            Save
          </Button>
        </div>
      )}
      <div className="input__image__file">
        <input
          type="file"
          id={label}
          hidden
          onChange={ImageChangeHandler}
          accept="image/*"
        />
        {/* <label htmlFor="input_file">Choose a file</label> */}
      </div>
    </Col>
  );
}
