import React from "react";
import * as Yup from "yup";

import API from "../../../API";
import CRUDTable from "../../../Shared/CRUDTable";
import * as validationSchemas from "../../../validationSchemas";

const validationSchema = Yup.object({
  title: validationSchemas.readableText.required(),
  duration: Yup.mixed().required(),
  sub_title: validationSchemas.readableText.required(),
  thumbnail: Yup.mixed().required(),
  vdo: Yup.mixed().label("Video").required(),
});

const Videos = () => {
  return (
    <CRUDTable
      API={API("trainingvid")}
      idExtractor={(data) => data.vid_id}
      tableName="Videos"
      hasFormData
      config={{
        columns: [
          {
            label: "Title",
            db_name: "title",
            gridOptions: { md: 6 },
          },
          {
            label: "Duration",
            db_name: "duration",
            gridOptions: { md: 6 },
            inputOptions: {
              type: "time",
            },
          },
          {
            label: "Sub title",
            db_name: "sub_title",
            gridOptions: { md: 12 },
            inputOptions: {
              type: "textarea",
            },
          },
          {
            label: "Thumbnail",
            db_name: "thumbnail",
            gridOptions: { md: 6 },
            transformFunc: (link) => (
              <a href={link} target="_blank" rel="noopenner noreferrer">
                Show
              </a>
            ),
            inputOptions: {
              type: "file",
              accept: "image/*",
            },
          },
          {
            label: "Video",
            db_name: "vdo",
            gridOptions: { md: 6 },
            transformFunc: (link) => (
              <a href={link} target="_blank" rel="noopenner noreferrer">
                Show
              </a>
            ),
            inputOptions: {
              type: "file",
              accept: "video/*",
            },
          },
        ],
        formikProps: { validationSchema },
      }}
    />
  );
};

export default Videos;
