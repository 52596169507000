/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useState } from "react";
import { FastField, Field, FieldArray, getIn, useFormikContext } from "formik";
import { useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import { isEmpty } from "../../utils";

const FormControl = ({ innerRef, ...props }) => (
  <Form.Control ref={innerRef} {...props} />
);

const TextArea = (props) => <FormControl as="textarea" {...props} />;

const FieldWithErrorMsg = forwardRef((props, ref) => {
  const {
    name,
    label,
    className,
    placeholder,
    formText,
    as,
    options,
    optionsFetchAPI,
    getOptionsFetchAPI,
    optionFetchRequestBodyTransformer,
    optionTransformer,
    optionValueTransformer,
    optionDisbaledStateExtractor,
    optionDependencies,
    fast,
    required,
    renderError,
    isInvalid,
    labelClass = "",
    getValue,
    useRawForm = false,
    value,
    onChange,
    ...rest
  } = props;

  const { touched, errors, setFieldValue, values } = useFormikContext();
  const [fetchedOptions, setFetchedOptions] = useState();

  useEffect(() => {
    if (["select", "multi-select"].includes(rest.type)) {
      if (optionsFetchAPI || getOptionsFetchAPI)
        (getOptionsFetchAPI?.(values) ?? optionsFetchAPI)(
          optionFetchRequestBodyTransformer?.(values)
        ).then((options) => {
          const defaultOption = options.filter(
            (option) => !optionDisbaledStateExtractor?.(option) ?? true
          )?.[0];

          setFetchedOptions(options);

          if (rest.type === "select")
            setFieldValue(
              name,
              optionValueTransformer
                ? optionValueTransformer(getIn(values, name) || defaultOption)
                : getIn(values, name) || defaultOption
            );
          else {
            const currentValue = getIn(values, name);
            let options = isEmpty(currentValue) ? defaultOption : currentValue;
            options = Array.isArray(options) ? options : [options];
            options = options.map(
              (option) => optionValueTransformer?.(option) ?? option
            );
            setFieldValue(name, options);
          }
        });
      else {
        const defaultOption =
          options.filter(
            (option) => optionDisbaledStateExtractor?.(option) ?? true
          )?.[0] ?? options[0];

        setFieldValue(
          name,
          optionValueTransformer
            ? optionValueTransformer(getIn(values, name) || defaultOption)
            : getIn(values, name) || defaultOption
        );
      }
    }
  }, optionDependencies?.map((dependency) => getIn(values, dependency)) ?? []);

  const randId = useMemo(() => Math.random(10000).toString(22), []);
  const ComponentToBeUsedForField = fast ? FastField : Field;

  return (
    <>
      {label !== "" && (
        <Form.Label
          htmlFor={name + randId}
          className={"text-capitalize " + labelClass}
        >
          {label || name.replaceAll("_", " ")}
          {required && <span className="text-danger">*</span>}
        </Form.Label>
      )}
      {useRawForm ? (
        <Form.Control
          {...{
            value,
            name,
            onChange,
            className,
            placeholder,
            as,
            type: rest.type,
          }}
        />
      ) : rest.type === "select" ? (
        <ComponentToBeUsedForField
          as={as || Form.Select}
          className={`${
            ((getIn(touched, name) && getIn(errors, name)) || isInvalid) &&
            "is-invalid"
          } ${className}`}
          name={name}
          id={name + randId}
          placeholder={(
            placeholder ||
            label ||
            name.replaceAll("_", " ")
          ).capitalize()}
          {...rest}
        >
          {(options ?? fetchedOptions)?.map((option, idx) => (
            <option
              key={idx}
              value={
                optionValueTransformer ? optionValueTransformer(option) : option
              }
              disabled={optionDisbaledStateExtractor?.(option) ?? false}
            >
              {optionTransformer ? optionTransformer(option) : option}
            </option>
          ))}
        </ComponentToBeUsedForField>
      ) : rest.type === "multi-text" ? (
        <FieldArray name={name}>
          {({ push }) => (
            <Form.Group>
              {getIn(values, name).map?.((point, idx) => (
                <ComponentToBeUsedForField
                  key={idx}
                  as={as || FormControl}
                  name={`${name}[${idx}]`}
                  placeholder={(
                    placeholder ||
                    label ||
                    name.replaceAll("_", " ")
                  ).capitalize()}
                  className={`${
                    ((getIn(touched, name) && getIn(errors, name)) ||
                      isInvalid) &&
                    "is-invalid"
                  } mb-2 ${className}`}
                />
              ))}
              <div>
                <Button onClick={() => push("")}>Add More</Button>
              </div>
            </Form.Group>
          )}
        </FieldArray>
      ) : rest.type === "multi-select" ? (
        <FieldArray name={name}>
          {({ push }) => (
            <Form.Group>
              {getIn(values, name).map?.((point, idx) => (
                <ComponentToBeUsedForField
                  key={idx}
                  as={as || Form.Select}
                  className={`${
                    ((getIn(touched, name) && getIn(errors, name)) ||
                      isInvalid) &&
                    "is-invalid"
                  } mb-2 ${className}`}
                  name={`${name}[${idx}]`}
                  placeholder={(
                    placeholder ||
                    label ||
                    name.replaceAll("_", " ")
                  ).capitalize()}
                  {...rest}
                >
                  {(options ?? fetchedOptions)?.map((option, idx) => (
                    <option
                      key={idx}
                      value={optionValueTransformer?.(option) ?? option}
                      disabled={optionDisbaledStateExtractor?.(option) ?? false}
                    >
                      {optionTransformer?.(option) ?? option}
                    </option>
                  ))}
                </ComponentToBeUsedForField>
              ))}
              <div>
                <Button onClick={() => push("")}>Add More</Button>
              </div>
            </Form.Group>
          )}
        </FieldArray>
      ) : rest.type === "file" ? (
        <FormControl
          innerRef={ref}
          className={`${
            ((getIn(touched, name) && getIn(errors, name)) || isInvalid) &&
            "is-invalid"
          } ${className}`}
          name={name}
          id={name + randId}
          placeholder={(
            placeholder ||
            label ||
            name.replaceAll("_", " ")
          ).capitalize()}
          // value={getIn(values, name)}
          onChange={({
            target: {
              files: [file],
            },
          }) => {
            if (file) {
              setFieldValue(name, file);
            }
          }}
          {...rest}
        />
      ) : rest.type === "textarea" ? (
        <ComponentToBeUsedForField
          as={as || TextArea}
          innerRef={ref}
          className={`${
            ((getIn(touched, name) && getIn(errors, name)) || isInvalid) &&
            "is-invalid"
          } ${className}`}
          name={name}
          id={name + randId}
          placeholder={(
            placeholder ||
            label ||
            name.replaceAll("_", " ")
          ).capitalize()}
          {...rest}
        />
      ) : (
        <ComponentToBeUsedForField
          as={as || FormControl}
          innerRef={ref}
          className={`${
            ((getIn(touched, name) && getIn(errors, name)) || isInvalid) &&
            "is-invalid"
          } ${className}`}
          name={name}
          id={name + randId}
          placeholder={(
            placeholder ||
            label ||
            name.replaceAll("_", " ")
          ).capitalize()}
          {...(getValue
            ? {
                value: getValue(getIn(values, name)),
              }
            : {})}
          {...rest}
        />
      )}

      {renderError?.() ??
        (getIn(touched, name) && getIn(errors, name) && (
          <div className="invalid-feedback">
            {getIn(errors, name)[0].toUpperCase() +
              getIn(errors, name).slice(1)}
          </div>
        ))}
      {formText && <Form.Text>{formText}</Form.Text>}
    </>
  );
});

export default FieldWithErrorMsg;
