import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Actions } from "../../Redux/rootReducer";
import "./index.scss";

const Header = () => {
  const admin = useSelector(({ AuthReducer: { data } }) => data);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("data");
    dispatch(Actions.Misc.resetStore());
    navigate("/login", { replace: true });
    window.location.reload();
  };

  return (
    <div className="header-container">
      <div className="header d-flex justify-content-end d-flex justify-content-end p-2">
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-basic"
            className="rounded-circle no-caret"
          >
            {admin?.name?.[0]?.toUpperCase()}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <div>
                <Button variant="icon" onClick={logout}>
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="me-1 text-danger"
                  />
                  <span className="text-danger">Log out</span>
                </Button>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
