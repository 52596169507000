import React from "react";
import * as Yup from "yup";

import API from "../../../API";
import CRUDTable from "../../../Shared/CRUDTable";
import * as validationSchemas from "../../../validationSchemas";

const validationSchema = Yup.object({
  name: validationSchemas.full_name.label("Full name").required(),
  phone: validationSchemas.phone.required(),
  email: validationSchemas.email,
  age: validationSchemas.age.required(),
  address: Yup.object({
    alt_phone: validationSchemas.phone.label("Alternate phone number"),
    street: validationSchemas.address
      .label("Street/Apartment/House no.")
      .required(),
    landmark: validationSchemas.readableText.label("landmark").required(),
    city: validationSchemas.city.label("city").required(),
    state: validationSchemas.state.label("state").required(),
    pincode: validationSchemas.zip.label("pincode").required(),
  }),
});

const Patients = () => {
  return (
    <CRUDTable
      API={API("patient")}
      idExtractor={(data) => data.patient_id}
      tableName="Patients"
      hideDeleteButton
      hideAddButton
      config={{
        columns: [
          {
            label: "patient name",
            db_name: "name",
            gridOptions: { md: 6 },
          },
          {
            label: "mobile number",
            db_name: "phone",
            gridOptions: { md: 6 },
          },
          { label: "email", db_name: "email", gridOptions: { md: 6 } },
          {
            label: "gender",
            db_name: "gender",
            gridOptions: { md: 6 },
            inputOptions: {
              type: "select",
              options: ["male", "female", "other"],
            },
          },
          {
            label: "age",
            db_name: "age",
            gridOptions: { md: 6 },
            inputOptions: {
              type: "number",
            },
          },
          {
            label: "active package",
            db_name: "package_name",
            notEditable: true,
          },
          {
            label: "active package",
            db_name: "active_package",
            gridOptions: { md: 6 },
            hideFromTable: true,
            transformFunc: (data) => data.title,
            inputOptions: {
              type: "select",
              optionsFetchAPI: API("package").get(),
              optionTransformer: (option) => option.title,
              optionValueTransformer: (option) => option.package_id,
            },
          },
          {
            label: "address",
            db_name: "address",
            notEditable: true,
            transformFunc: ({ street, landmark, city, state, pincode }) => (
              <div>
                <p className="mb-0">
                  {street}, {landmark}
                </p>
                <p className="mb-0">
                  {city}, {state}, {pincode}
                </p>
              </div>
            ),
          },
          {
            label: "patient address",
            db_name: "address",
            children: [
              {
                label: "Street/Apartment/House no.",
                db_name: "street",
                inputOptions: {
                  type: "textarea",
                },
              },
              {
                label: "alternate phone",
                db_name: "alt_phone",
                gridOptions: { md: 6 },
              },
              {
                label: "landmark",
                db_name: "landmark",
                gridOptions: { md: 6 },
              },
              {
                label: "city",
                db_name: "city",
                gridOptions: { md: 4 },
              },
              {
                label: "state",
                db_name: "state",
                gridOptions: { md: 4 },
              },
              {
                label: "pincode",
                db_name: "pincode",
                gridOptions: { md: 4 },
              },
            ],
          },
        ],
        formikProps: { validationSchema },
      }}
    />
  );
};

export default Patients;
