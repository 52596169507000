import React from "react";
import * as Yup from "yup";
import API from "../../../API";
import CRUDTable from "../../../Shared/CRUDTable";
import * as validationSchemas from "../../../validationSchemas";

const validationSchema = Yup.object({
  name: validationSchemas.readableText.required(),
  price: validationSchemas.price.required(),
  sale_price: validationSchemas.price.label("Sale price"),
  max_order_limit: validationSchemas.positiveNumber
    .integer()
    .label("Max order limit")
    .required(),
  stocks: validationSchemas.positiveNumber.integer().required(),
  details: validationSchemas.readableText.required(),
  dosage: validationSchemas.readableText,
  instructions: Yup.array().of(validationSchemas.readableText),
  composition: Yup.array().of(validationSchemas.readableText),
  // images: Yup.array().of(validationSchemas.file).required(),
});

const Products = () => {
  return (
    <CRUDTable
      // pending={pending}
      API={API("product")}
      tableName="Products"
      idExtractor={(data) => data.p_id}
      modalSize="xl"
      hasFormData
      postURL="from-admin"
      putURL="from-admin"
      requestBodyTransformer={(body) => {
        const formData = new FormData();
        for (let key in body) {
          let payload = body[key];
          if (key === "images") {
            Object.values(payload).forEach((image) => {
              image !== "" && formData.append("images", image);
            });
            continue;
          } else if (key === "composition" || key === "instructions") {
            formData.append(key, JSON.stringify(payload));
            continue;
          }
          formData.append(key, payload);
        }
        return formData;
      }}
      config={{
        modalInitialValues: {
          composition: [""],
          instructions: [""],
        },
        columns: [
          {
            label: "image",
            db_name: "images",
            gridOptions: { md: 4 },
            notEditable: true,
            transformFunc: (data) => (
              <img
                src={data.images?.[0]}
                width="90px"
                style={{ objectFit: "contain" }}
              />
            ),
          },
          {
            label: "name",
            db_name: "name",
          },
          {
            label: "category",
            db_name: "cat_id",
            transformFunc: (data) => data.cat_name,
            gridOptions: { md: 4 },
            inputOptions: {
              type: "select",
              optionsFetchAPI: API("product/category").get(),
              optionTransformer: (option) => option.label,
              optionValueTransformer: (option) => option.cat_id,
            },
          },
          {
            label: "price",
            db_name: "price",
            gridOptions: { md: 4 },
          },
          {
            label: "sale price",
            db_name: "sale_price",
            gridOptions: { md: 4 },
          },
          {
            label: "max order limit",
            db_name: "max_order_limit",
            gridOptions: { md: 4 },
            inputOptions: {
              type: "number",
              min: 1,
              formText:
                "The maximum units of this product that can be purchased in one order",
            },
          },
          {
            label: "gender",
            db_name: "sex",
            gridOptions: { md: 4 },
            inputOptions: {
              type: "select",
              options: ["male", "female", "other", "unisex"],
            },
          },
          {
            label: "stocks",
            db_name: "stocks",
            gridOptions: { md: 4 },
            inputOptions: {
              type: "number",
            },
            emptyText: "Out of Stock",
          },
          {
            label: "details",
            db_name: "details",
            gridOptions: { md: 12 },
            inputOptions: {
              type: "textarea",
              rows: 5,
            },
          },
          {
            label: "dosage",
            db_name: "dosage",
            gridOptions: { md: 12 },
            inputOptions: {
              type: "textarea",
              rows: 5,
            },
          },
          {
            label: "instructions",
            db_name: "instructions",
            gridOptions: { md: 12 },
            inputOptions: {
              type: "multi-text",
            },
            hideFromTable: true,
          },
          {
            label: "composition",
            db_name: "composition",
            gridOptions: { md: 12 },
            inputOptions: {
              type: "multi-text",
            },
            hideFromTable: true,
          },
          {
            label: "product images",
            db_name: "images",
            children: [
              {
                label: "image 1",
                db_name: "0",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "file",
                },
              },
              {
                label: "image 2",
                db_name: "1",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "file",
                },
              },
              {
                label: "image 3",
                db_name: "2",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "file",
                },
              },
              {
                label: "image 4",
                db_name: "3",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "file",
                },
              },
              {
                label: "image 5",
                db_name: "4",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "file",
                },
              },
              {
                label: "image 6",
                db_name: "5",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "file",
                },
              },
            ],
          },
        ],
        formikProps: { validationSchema },
      }}
    />
  );
};

export default Products;
