import { store } from "..";
import { API_URL } from "../constants";
import { getAccessToken } from "../utils";
import { Actions } from "./Redux/rootReducer";

const overEngineeredFetch = async (url, options, retry = 0, timeout = 180) => {
  const res = await Promise.race([
    fetch(API_URL + url, options),
    new Promise((res) =>
      setTimeout(() => res({ timed_out: true }), timeout * 1000)
    ),
  ]);
  if (res.timed_out) {
    if (retry === 0) throw Error("timed out");
    return await overEngineeredFetch(url, options, retry - 1, timeout);
  } else {
    const data = await res.json();
    if (res.ok) {
      return data;
    } else if (
      data === "srt" ||
      data === "login_needed" ||
      res.status === "403"
    ) {
      localStorage.removeItem("data");
      store.dispatch(Actions.Misc.resetStore());
      window.location.reload();
    } else throw Error(data.message ?? data);
  }
};

export default function API(wrapper = "", withToken = true) {
  if (wrapper.length > 0) wrapper += "/";
  const authOptions = withToken
    ? { Authorization: "Bearer " + getAccessToken() }
    : {};
  return {
    get:
      (url = "", options = {}) =>
      async () =>
        await overEngineeredFetch(wrapper + url, {
          method: "GET",
          headers: {
            ...authOptions,
            ...options,
          },
        }),
    post: {
      JSON:
        (url = "", options = {}, transformFunc) =>
        async (body) => {
          body = transformFunc?.(body) ?? body;
          return await overEngineeredFetch(wrapper + url, {
            method: "POST",
            headers: {
              ...authOptions,
              "Content-Type": "application/json",
              ...options,
            },
            body: JSON.stringify(body),
          });
        },
      FormData:
        (url = "", options = {}, transformFunc) =>
        async (body) => {
          var formData;
          if (transformFunc) formData = transformFunc(body);
          else {
            formData = new FormData();
            for (let key in body) {
              let payload = body[key];
              if (!(payload instanceof File) && typeof body[key] === "object")
                payload = JSON.stringify(payload);
              formData.append(key, payload);
            }
          }
          return await overEngineeredFetch(wrapper + url, {
            method: "POST",
            headers: {
              ...authOptions,
              ...options,
            },
            body: formData,
          });
        },
    },
    put: {
      JSON:
        (url = "", options = {}, transformFunc) =>
        async (body) => {
          body = transformFunc?.(body) ?? body;
          return await overEngineeredFetch(wrapper + url, {
            method: "PUT",
            headers: {
              ...authOptions,
              "Content-Type": "application/json",
              ...options,
            },
            body: JSON.stringify(body),
          });
        },
      FormData:
        (url = "", options = {}, transformFunc) =>
        async (body) => {
          let formData;

          if (transformFunc) formData = transformFunc(body);
          else {
            formData = new FormData();
            for (let key in body) {
              let payload = body[key];
              if (!(payload instanceof File) && typeof body[key] === "object")
                payload = JSON.stringify(payload);
              formData.append(key, payload);
            }
          }
          return await overEngineeredFetch(wrapper + url, {
            method: "PUT",
            headers: {
              ...authOptions,
              ...options,
            },
            body: formData,
          });
        },
    },
    patch: {
      JSON:
        (url = "", options = {}) =>
        async (body) =>
          await overEngineeredFetch(wrapper + url, {
            method: "PATCH",
            headers: {
              ...authOptions,
              "Content-Type": "application/json",
              ...options,
            },
            body: JSON.stringify(body),
          }),
      FormData:
        (url = "", options = {}) =>
        async (body) => {
          const formData = new FormData();
          for (let key in body) {
            let payload = body[key];
            if (typeof body[key] === "object")
              payload = JSON.stringify(payload);
            formData.append(key, payload);
          }
          return await overEngineeredFetch(wrapper + url, {
            method: "PATCH",
            headers: {
              ...authOptions,
              ...options,
            },
            body: formData,
          });
        },
    },
    delete:
      (url = "", options = {}) =>
      async () =>
        await overEngineeredFetch(wrapper + url, {
          method: "DELETE",
          headers: {
            ...authOptions,
            ...options,
          },
        }),
  };
}
