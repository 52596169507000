import { useSelector } from "react-redux";
import { isAllowed } from "../../utils";

const ShowForRole = ({ roles = [], children }) => {
  const admin = useSelector(({ AuthReducer }) => AuthReducer.data ?? {});

  return isAllowed(roles, admin.role) && children;
};

export default ShowForRole;
