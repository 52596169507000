import React, { useCallback, useMemo } from "react";
import { Pagination } from "react-bootstrap";

const Paginator = ({ totalPage, activePage, setActivePage }) => {
  const changePageNumTo = useCallback(
    (pageNum) => {
      setActivePage(Math.max(0, Math.min(pageNum, totalPage)));
    },
    [totalPage]
  );

  const paginationTabs = useMemo(() => {
    const paginations = [];

    // Max 5 buttons
    const numOfTabs = Math.min(5, totalPage);

    const offSet =
      numOfTabs === 5
        ? activePage > totalPage - 2
          ? Math.max(0, activePage - Math.ceil(numOfTabs / 2)) -
            (2 - totalPage + activePage)
          : activePage > Math.ceil(numOfTabs / 2)
          ? Math.max(0, activePage - Math.ceil(numOfTabs / 2))
          : 0
        : 0;
    for (let i = 1; i <= numOfTabs; i++) {
      const num = i + offSet;

      paginations.push(
        <Pagination.Item
          active={num === activePage}
          onClick={() => changePageNumTo(num)}
        >
          {num}
        </Pagination.Item>
      );
    }

    return paginations;
  }, [activePage, totalPage]);

  return (
    totalPage > 1 && (
      <Pagination className="justify-content-center">
        <Pagination.First
          disabled={activePage === 1}
          onClick={() => changePageNumTo(1)}
        />
        <Pagination.Prev
          disabled={activePage === 1}
          onClick={() => changePageNumTo(activePage - 1)}
        />

        {paginationTabs}

        <Pagination.Next
          disabled={activePage === totalPage}
          onClick={() => {
            changePageNumTo(activePage + 1);
          }}
        />
        <Pagination.Last
          disabled={activePage === totalPage}
          onClick={() => changePageNumTo(totalPage)}
        />
      </Pagination>
    )
  );
};

export default Paginator;
