import React from "react";
import moment from "moment";
import API from "../../../API";
import CRUDTable from "../../../Shared/CRUDTable";

const BillAndPayments = () => {
  return (
    <CRUDTable
      API={API("bill-payment")}
      tableName="Bill and payments"
      idExtractor={(data) => data.apt_id}
      modalSize="xl"
      hideEditButton
      hideAddButton
      hideDeleteButton
      filterByConfig={[
        {
          label: "name",
          value: "name",
        },
        {
          label: "mobile number",
          value: "phone",
        },
        {
          label: "email",
          value: "email",
        },
        {
          label: "doctor name",
          value: "doctor_name",
        },
      ]}
      sortByConfig={[
        {
          label: "Price",
          value: "price",
        },
      ]}
      config={{
        columns: [
          {
            label: "name",
            db_name: "name",
          },
          {
            label: "mobile number",
            db_name: "phone",
          },
          {
            label: "email",
            db_name: "email",
          },
          {
            label: "doctor name",
            db_name: "doctor_name",
          },
          {
            label: "product name",
            db_name: "product_name",
          },
          {
            label: "consultancy type",
            db_name: "consultancy_type",
          },
          {
            label: "Price",
            db_name: "price",
          },
          {
            label: "Billed at",
            db_name: "created_at",
            transformFunc: (data) => data && moment(data).format("LL"),
          },
        ],
      }}
    />
  );
};

export default BillAndPayments;
