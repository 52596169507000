import {
  faImage,
  faMicrophone,
  faMicrophoneSlash,
  faPhone,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Actions } from "../../../../Redux/rootReducer";
import routes from "../../../../routes";
import { downloadURI } from "../../../../../utils";

const Meeting = () => {
  const { myStream, peers } = useSelector(
    ({ VideoCallReducer }) => VideoCallReducer
  );
  const [mic, setMic] = useState(true);
  const [video, setVideo] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!myStream) {
      navigate(
        `/${routes.Dashboard.path}/${routes.Dashboard.views.Appointment.path}`
      );
    }
  }, [myStream]);

  return (
    <Container
      fluid
      className="min-vh-100 meeting-container position-relative d-flex align-items-center"
    >
      <Row className="justify-content-center" style={{ flex: 1 }}>
        {myStream && <Stream stream={myStream} muted {...{ mic, video }} />}

        {[...peers].map(
          ([_, { stream, audio: mic, video }], idx) =>
            stream && <Stream {...{ stream, mic, video }} key={idx} />
        )}
      </Row>
      <MeetingControls {...{ mic, video, setMic, setVideo }} />
    </Container>
  );
};

const MediaIndicator = ({ mic, video }) => (
  <div
    className={`media-indicator absolute--fill position-absolute d-flex justify-content-center align-items-center ${
      !video && "bg-black"
    }`}
  >
    {!mic && (
      <FontAwesomeIcon
        icon={faMicrophoneSlash}
        size="lg"
        className="text-white mx-1"
      />
    )}
    {!video && (
      <FontAwesomeIcon
        icon={faVideoSlash}
        size="lg"
        className="text-white mx-1"
      />
    )}
  </div>
);

const MeetingControls = ({ mic, video, setMic, setVideo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLeave = () => {
    dispatch(
      Actions.VideoCall.leaveRoom({
        callback: () => {
          navigate(
            `/${routes.Dashboard.path}/${routes.Dashboard.views.Appointment.path}`
          );
          window.location.reload();
        },
      })
    );
  };
  const toggleMic = () => {
    setMic((mic) => {
      dispatch(Actions.VideoCall.toggleMedia({ kind: "audio", value: !mic }));
      return !mic;
    });
  };
  const toggleVideo = () => {
    setVideo((video) => {
      dispatch(Actions.VideoCall.toggleMedia({ kind: "video", value: !video }));
      return !video;
    });
  };

  return (
    <Row className="position-absolute bottom-0 bg-primary controls-container align-items-center justify-content-center w-100">
      <Col className="col-auto">
        <button
          className={`btn btn-${
            mic ? "light" : "primary"
          } btn-control d-flex align-items-center justify-content-center`}
          onClick={toggleMic}
        >
          <span>
            <FontAwesomeIcon
              icon={mic ? faMicrophone : faMicrophoneSlash}
              size="lg"
              className={mic ? "" : "text-white"}
            />
          </span>
        </button>
      </Col>
      <Col className="col-auto">
        <button
          className={`btn btn-${
            video ? "light" : "primary"
          } btn-control d-flex align-items-center justify-content-center`}
          onClick={toggleVideo}
        >
          <span>
            <FontAwesomeIcon
              icon={video ? faVideo : faVideoSlash}
              size="lg"
              className={video ? "" : "text-white"}
            />
          </span>
        </button>
      </Col>
      <Col className="col-auto">
        <button
          className="btn btn-danger btn-control d-flex align-items-center justify-content-center"
          onClick={handleLeave}
        >
          <span>
            <FontAwesomeIcon icon={faPhone} className="hangup-icon" />
          </span>
        </button>
      </Col>
    </Row>
  );
};

const ScreenshotButton = ({ videoRef }) => {
  const handleTakeScreenshot = () => {
    const canvas = document.createElement("canvas");

    canvas.width = 640;
    canvas.height = 480;

    const ctx = canvas.getContext("2d");

    //draw image to canvas. scale to target dimensions
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    //convert to desired file format
    var dataURI = canvas.toDataURL("image/jpeg");

    downloadURI(dataURI, "meeting_screenshot.png");
  };

  return (
    <div className="position-absolute top-0 start-0 end-0 text-end pt-4 pe-2 ss-btn">
      <Button variant="light" size="sm" title="Take screenshot">
        <FontAwesomeIcon
          icon={faImage}
          onClick={handleTakeScreenshot}
          size="lg"
        />
      </Button>
    </div>
  );
};

const Stream = ({ stream, mic, video, muted = false }) => {
  const ref = useRef();

  useEffect(() => {
    ref.current.srcObject = stream;
  }, [stream]);

  return (
    <Col
      xs={12}
      md={6}
      className="d-flex justify-content-center align-items-center"
    >
      <div className="position-relative video overflow-hidden stream">
        <video
          playsInline
          autoPlay
          muted={muted}
          ref={ref}
          className="video my-3"
        />
        <MediaIndicator {...{ mic, video }} />
        <ScreenshotButton videoRef={ref} />
      </div>
    </Col>
  );
};

export default Meeting;
