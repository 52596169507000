import {
  faAd,
  faBox,
  faBoxOpen,
  faCalendarCheck,
  faClipboardList,
  faHourglassStart,
  faMoneyBillWave,
  faPhotoVideo,
  faPlusSquare,
  faTachometerAlt,
  faTrophy,
  faUserCog,
  faUserNurse,
  faUsers,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Roles } from "../constants";
import Dashboard, { DashboardViews } from "./Screens/Dashboard";
import Login from "./Screens/Login";

const routes = {
  Dashboard: {
    element: <Dashboard />,
    authRequired: true,
    path: "dashboard",
    views: {
      Home: {
        path: "home",
        element: <DashboardViews.Home />,
        icon: faTachometerAlt,
        name: "Dashboard",
        roles: [Roles["Center Manager"]],
      },
      Appointment: {
        path: "appointment",
        element: <DashboardViews.Appointments />,
        icon: faUsers,
        name: "Appointments",
        roles: [
          Roles["Center Manager"],
          Roles.Doctor,
          Roles["Pharmacist & Accountant"],
        ],
      },
      Patients: {
        path: "patients",
        element: <DashboardViews.Patients />,
        icon: faClipboardList,
        name: "Patients",
        roles: [Roles["Center Manager"], Roles.Doctor],
      },
      Packages: {
        path: "packages",
        element: <DashboardViews.Packages />,
        icon: faCalendarCheck,
        name: "Packages",
        roles: [Roles["Center Manager"], Roles["Pharmacist & Accountant"]],
      },
      Orders: {
        path: "orders",
        element: <DashboardViews.Order />,
        icon: faBox,
        name: "Orders",
        roles: [Roles["Marketing"], Roles["Pharmacist & Accountant"]],
      },
      Products: {
        path: "products",
        element: <DashboardViews.Products />,
        icon: faBoxOpen,
        name: "Products",
        roles: [Roles["Marketing"], Roles["Pharmacist & Accountant"]],
      },
      Prescription: {
        path: "prescriptions",
        element: <DashboardViews.Prescription />,
        icon: faHourglassStart,
        name: "Prescriptions",
        roles: [Roles["Center Manager"], Roles.Doctor],
      },
      Doctors: {
        path: "doctors",
        element: <DashboardViews.Doctors />,
        icon: faUserNurse,
        name: "Doctors",
        roles: [Roles["Center Manager"]],
      },
      BillAndPayments: {
        path: "bill-payments",
        element: <DashboardViews.BillAndPayments />,
        icon: faMoneyBillWave,
        name: "Bill and Payments",
        roles: [Roles["Center Manager"], Roles["Pharmacist & Accountant"]],
      },
      AddBanner: {
        path: "add-banner",
        element: <DashboardViews.AddBanner />,
        icon: faPlusSquare,
        name: "Banners",
        roles: [Roles["Marketing"]],
      },
      Videos: {
        path: "videos",
        element: <DashboardViews.Videos />,
        icon: faPhotoVideo,
        name: "Videos",
        roles: [Roles["Marketing"]],
      },
      SpecialOffer: {
        path: "special-offer",
        element: <DashboardViews.SpecialOffers />,
        icon: faUserTag,
        name: "Special Offers",
        roles: [Roles["Marketing"]],
      },
      Lead: {
        path: "leads",
        element: <DashboardViews.Leads />,
        icon: faTrophy,
        name: "Leads",
        roles: [Roles["Marketing"]],
      },
      Administration: {
        path: "administration",
        element: <DashboardViews.Administration />,
        icon: faUserCog,
        name: "Administration",
        roles: [Roles["Super Admin"]],
      },
      Marketing: {
        path: "marketing",
        element: <DashboardViews.Marketing />,
        icon: faAd,
        name: "Marketing",
        roles: [Roles["Marketing"]],
      },
    },
  },
  Meeting: {
    path: "meeting",
    element: <DashboardViews.Meeting />,
    hideFromSidebar: true,
  },
  Login: {
    element: <Login />,
    path: "login",
    hideFromSidebar: true,
  },
};

export default routes;
