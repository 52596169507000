/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Table as BTable, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Table = (props) => {
  let {
    columns,
    pending,
    data,
    renderActions,
    className,
    hideActions,
    userChecked,
  } = props;
  const location = useLocation();
  return (
    <div className="position-relative">
      <BTable responsive striped className={className ?? ""}>
        <thead>
          <tr>
            {location.pathname.split("/")[2] !== "home" ? (
              <th>
                <Form.Check
                  onChange={(e) => {
                    let checked = e.target.checked;
                    props.setAllChecked(checked);
                  }}
                  type="checkbox"
                  id="th-checkbox"
                />
              </th>
            ) : null}

            <th>SL</th>
            {columns.map(
              ({ label, hideFromTable, children }, idx) =>
                hideFromTable || !!children || <th key={idx}>{label}</th>
            )}
            {hideActions && <th>Actions</th>}
          </tr>
        </thead>
        {!pending && data && (
          <tbody>
            {data.map((row, idx) => (
              <tr key={idx}>
                {location.pathname.split("/")[2] !== "home" ? (
                  <td>
                    <Form.Check
                      onChange={(e) => {
                        props.setChecked(idx);
                      }}
                      checked={userChecked[idx].checked}
                      type="checkbox"
                      id="td-checkbox"
                    />
                  </td>
                ) : null}
                <td>{idx + 1}</td>
                {columns.map(
                  (
                    {
                      db_name,
                      transformFunc,
                      hideFromTable,
                      children,
                      transformFuncFromRow,
                      emptyText,
                    },
                    idx
                  ) =>
                    hideFromTable ||
                    !!children || (
                      <td key={idx}>
                        {(() => {
                          const columnData =
                            transformFuncFromRow?.(row) ??
                            transformFunc?.(row[db_name]) ??
                            row[db_name]?.toString();
                          return columnData
                            ? columnData.length > 40
                              ? `${columnData.slice(0, 40)}...`
                              : columnData
                            : emptyText
                            ? emptyText
                            : "-";
                        })()}
                      </td>
                    )
                )}
                {hideActions && (
                  <td>
                    <div className="d-flex justify-content-start">
                      {renderActions(row)}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        )}
      </BTable>
      {!pending && !data?.length > 0 && (
        <div className="position-absolute absolute--fill w-100 d-flex align-items-center justify-content-center">
          <h5 className="text-center pt-4 text-secondary">No Data Available</h5>
        </div>
      )}
    </div>
  );
};

Table.prototype = {
  columns: PropTypes.arrayOf(
    PropTypes.objectOf({
      lable: PropTypes.string,
      db_name: PropTypes.string,
      gridOptions: PropTypes.object,
      inputOptions: PropTypes.object,
    })
  ),
  pending: PropTypes.bool,
  data: PropTypes.array,
  renderActions: PropTypes.func,
  className: PropTypes.string,
};

export default Table;
