import {
  faPlus,
  faSave,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, getIn } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import { daysInWeek } from "../../../../../../utils";
import DeleteButton from "../../../../../Shared/DeleteButton";

const WEEK_DAY = "week_day";
const START_TIME = "s_time";
const END_TIME = "e_time";
const MODE = "mode";
const InitialValue = {
  week_day: 0,
  s_time: "10:00",
  e_time: "11:00",
  mode: true,
};

const GetTimeslots = ({ name, formMode }) => {
  return (
    <FieldArray name={name}>
      {({ form, unshift, ...arrayHelpers }) => (
        <Col xs={12} className="mt-3">
          <Row>
            <Col className="mb-2 pb-2 border-bottom border-1 d-flex justify-content-between align-items-center">
              <h5 className="text-capitalize">{formMode} timeslots</h5>
              <AddTimeSlotButton {...{ unshift, formMode }} />
            </Col>
          </Row>
          <Row>
            <TimeslotList {...{ form, name, arrayHelpers, formMode }} />
          </Row>
        </Col>
      )}
    </FieldArray>
  );
};

export default GetTimeslots;

const AddTimeSlotButton = ({ unshift, formMode }) => {
  useEffect(() => {
    if (formMode !== "edit") unshift(InitialValue);
  }, []);

  return (
    <Button size="sm" onClick={() => unshift(InitialValue)}>
      <FontAwesomeIcon icon={faPlus} />
    </Button>
  );
};

const TimeslotList = ({ form, name, arrayHelpers, formMode }) => {
  const itemsPerPage = 10;
  const data = getIn(form.values, name).map((timeslot, serial) => (
    <TimeSlot
      key={serial}
      {...{
        arrayHelpers,
        serial,
        timeslot,
        name,
        formMode,
        form,
      }}
    />
  ));

  const [hasMore, setHasMore] = useState(true);
  const [records, setrecords] = useState(itemsPerPage);

  const showItems = (posts) => {
    var items = [];
    for (var i = 0; i < records; i++) {
      items.push(posts[i]);
    }
    return items;
  };

  const loadMore = () => {
    if (records === data.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage);
      }, 1000);
    }
  };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      useWindow={false}
    >
      {showItems(data)}
    </InfiniteScroll>
  );
};

const TimeSlot = ({ arrayHelpers, timeslot, name, serial, formMode, form }) => {
  const [weekDay, setWeekDay] = useState(timeslot.week_day);
  const [startTime, setStartTime] = useState(timeslot.s_time);
  const [endTime, setEndTime] = useState(timeslot.e_time);
  const [mode, setMode] = useState(timeslot.mode);

  useEffect(() => {
    const { week_day, s_time, e_time, mode } = timeslot;
    setWeekDay(week_day);
    setStartTime(s_time);
    setEndTime(e_time);
    setMode(mode);
  }, [timeslot]);

  const hasFormChanged = useMemo(() => {
    const { week_day, s_time, e_time, mode: _mode } = timeslot;
    return (
      week_day !== weekDay ||
      s_time !== startTime ||
      e_time !== endTime ||
      _mode !== mode
    );
  }, [timeslot, weekDay, startTime, endTime]);

  const handleChange = (what, value) => {
    if (formMode === "edit") {
      switch (what) {
        case WEEK_DAY:
          setWeekDay(value);
          break;
        case START_TIME:
          setStartTime(value);
          break;
        case END_TIME:
          setEndTime(value);
          break;
        case MODE:
          setMode(value);
          break;
        default:
          break;
      }
    } else {
      form.setFieldValue(`${name}[${serial}].${what}`, value);
    }
  };

  const handleCancel = () => {
    const { week_day, s_time, e_time, mode } = timeslot;

    setWeekDay(week_day);
    setStartTime(s_time);
    setEndTime(e_time);
    setMode(mode);
  };

  const handleSave = async () => {
    arrayHelpers.replace(serial, {
      [WEEK_DAY]: weekDay,
      [START_TIME]: startTime,
      [END_TIME]: endTime,
      [MODE]: mode,
    });
  };

  const handleDelete = async () => {
    if (formMode === "edit") {
      // do API call

      // then update local state
      arrayHelpers.remove(serial);
    } else {
      arrayHelpers.remove(serial);
    }
  };

  return (
    <Row className="d-flex col col-12 mb-3 align-items-center">
      <Col xs={12} md={11} className="d-flex justify-content-evenly">
        <Form.Select
          className="me-2"
          value={formMode === "edit" ? weekDay : timeslot[WEEK_DAY]}
          onChange={(e) => handleChange(WEEK_DAY, e.target.value)}
        >
          {daysInWeek.map((day, idx) => (
            <option key={idx} value={idx}>
              {day}
            </option>
          ))}
        </Form.Select>
        <Form.Select
          value={formMode === "edit" ? mode : timeslot[MODE]}
          className="me-2"
          onChange={(e) => handleChange(MODE, e.target.value === "online")}
        >
          {["online", "offline"].map((mode, idx) => (
            <option key={idx} value={mode}>
              {mode}
            </option>
          ))}
        </Form.Select>
        <Form.Control
          value={formMode === "edit" ? startTime : timeslot[START_TIME]}
          className="me-2"
          type="time"
          onChange={(e) => handleChange(START_TIME, e.target.value)}
        />
        <Form.Control
          value={formMode === "edit" ? endTime : timeslot[END_TIME]}
          className="me-2"
          type="time"
          onChange={(e) => handleChange(END_TIME, e.target.value)}
        />
      </Col>
      <Col className="d-flex justify-content-between">
        {formMode === "edit" && (
          <Button
            size="sm"
            onClick={handleSave}
            variant="success"
            disabled={!hasFormChanged}
          >
            <FontAwesomeIcon icon={faSave} />
          </Button>
        )}
        {formMode === "edit" ? (
          hasFormChanged ? (
            <Button variant="light" size="sm" onClick={handleCancel}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          ) : (
            <DeleteButton handleDelete={handleDelete} what="this timeslot" />
          )
        ) : (
          <Button variant="danger" size="sm" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />{" "}
          </Button>
        )}
      </Col>
    </Row>
  );
};
