/* eslint-disable react/prop-types */
import { useFormikContext } from "formik";
import React from "react";
import { Button, Fade, Spinner } from "react-bootstrap";

const ButtonWithLoading = ({
  children,
  spinnerStyle = {},
  spinnerClass = "",
  spinnerProps = {},
  type = "submit",
  className = "",
  loading = false,
  ...rest
}) => {
  const formikContext = useFormikContext();
  if (formikContext) var { isSubmitting, isValid } = formikContext;
  const disabled = formikContext
    ? isSubmitting || !isValid || loading
    : loading || rest.disabled;
  return (
    <Button
      type={type}
      className={
        "position-relative d-flex justify-content-center align-items-center " +
        className
      }
      {...rest}
      disabled={disabled}
      variant={disabled ? "secondary" : "primary"}
    >
      <Fade in={loading || isSubmitting} className="me-1" unmountOnExit>
        <Spinner
          animation="border"
          size="sm"
          style={{ ...spinnerStyle }}
          className={spinnerClass}
          {...{ ...spinnerProps }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Fade>
      {children}
    </Button>
  );
};

export default ButtonWithLoading;
