import { useFormikContext, getIn } from "formik";
import React from "react";
import Item from "./Item";

const OrderItems = () => {
  const { values } = useFormikContext();
  const ordered_items = getIn(values, "ordered_items");

  return (
    <div className="mb-3">
      <h5 className="mb-2 pb-2 border-bottom border-1 d-flex justify-content-between align-items-center">
        Ordered Items
      </h5>
      <table className="w-100">
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {ordered_items?.map((item, idx) => (
            <Item {...{ item, key: idx }} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderItems;
