import {
  faFileVideo,
  faSpinner,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Roles } from "../../../../constants";
import { showNotification } from "../../../../utils";
import API from "../../../API";
import { Actions } from "../../../Redux/rootReducer";
import routes from "../../../routes";
import CRUDTable from "../../../Shared/CRUDTable";
import ShowForRole from "../../../Shared/ShowForRole";
import * as validationSchemas from "../../../validationSchemas";
import { Col, Container, Row } from "react-bootstrap";

const validationSchema = Yup.object({
  date: Yup.date().required(),
  s_time: Yup.mixed()
    .label("Time slot")
    .when("mode", {
      is: (mode) => String(mode) === "true",
      then: Yup.string().required(),
    }),
  time: Yup.mixed()
    .label("Time")
    .when("mode", {
      is: (mode) => String(mode) === "false",
      then: Yup.string().required(),
    }),
  patient_information: Yup.object({
    name: validationSchemas.full_name.label("name").required(),
    phone: validationSchemas.phone.label("phone").required(),
    email: validationSchemas.email.label("email"),
    age: validationSchemas.age.label("afe").required(),
  }),
  patient_address: Yup.object({
    alt_phone: validationSchemas.phone.label("Alternate phone number"),
    street: validationSchemas.address
      .label("Street/Apartment/House no.")
      .required(),
    landmark: validationSchemas.readableText.label("landmark").required(),
    city: validationSchemas.city.label("city").required(),
    state: validationSchemas.state.label("state").required(),
    pincode: validationSchemas.zip.label("pincode").required(),
  }),
});

const JoinMeetingButton = ({ appointmentDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleJoinRoom = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: { noiseSuppression: true, width: 1280, height: 720 },
        audio: { echoCancellation: true, noiseSuppression: true },
      })
      .then((stream) => {
        dispatch(
          Actions.VideoCall.joinRoom({
            stream,
            room: appointmentDetails.room_id,
            callback: () => navigate(`/${routes.Meeting.path}`),
          })
        );
      })
      .catch(console.error);
  };

  return (
    <Button
      title="Join meeting"
      size="sm"
      disabled={!appointmentDetails.mode}
      className="action-btn"
      onClick={handleJoinRoom}
    >
      <FontAwesomeIcon icon={faVideo} />
    </Button>
  );
};

const OfflineToOnlineButton = ({ appointmentDetails }) => {
  const [pending, setPending] = useState(false);
  const { apt_id, mode } = appointmentDetails;

  const handlePress = () => {
    setPending(true);
    API("appointment")
      .get(`convert-to-online/${apt_id}`)()
      .then(() => {
        showNotification({
          msg: "Successfully converted to online appointment",
        });
      })
      .catch((err) => {
        showNotification({
          msg: err.message,
          type: "danger",
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <Button
      title="Convert to online meeting"
      size="sm"
      disabled={mode || pending}
      className="action-btn"
      onClick={handlePress}
    >
      {pending ? (
        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
      ) : (
        <FontAwesomeIcon icon={faFileVideo} />
      )}
    </Button>
  );
};

const Appointments = () => {
  const admin = useSelector(({ AuthReducer }) => AuthReducer.data ?? {});
  const [Online, setOnline] = useState(true);
  const [Offline, setOffline] = useState(false);
  const [o_mobile, seto_mobile] = useState("");

  const renderExtraActionButtons = (row) => (
    <>
      <JoinMeetingButton appointmentDetails={row} />
      <ShowForRole roles={[Roles["Center Manager"], Roles.Doctor]}>
        <OfflineToOnlineButton appointmentDetails={row} />
      </ShowForRole>
    </>
  );

  return (
    <>
    <div className="crud-table-container">
      <div className="d-flex justify-content-between mb-3">
        {/* <h3>Test</h3> */}
        <div className="d-flex justify-content-between align-items-center">
        <Button
    title={"Edit "}
    size="sm"
    variant={Online?"primary":"outline-primary"}
    className="action-btn"
    style={{margin:'5px'}}
    onClick={()=>{setOnline(!Online);setOffline(false)}}
  >
  Online
  </Button>
  <Button
    title={"Edit "}
    size="sm"
    variant={!Online?"primary":"outline-primary"}
    className="action-btn"
    style={{margin:'5px'}}
    onClick={()=>{setOnline(false)}}
  >
  Offline
  </Button>
        </div>
        
      </div>
      {Online?
        <CRUDTable
      // pending={pending}
      API={API("appointment")}
      tableName="Online Appointments"
      idExtractor={(data) => data.apt_id}
      modalSize="xl"
      hideEditButton
      postURL="from-admin"
      getURLTransformer={(activePage) =>
        `?pageNum=${activePage}${
          admin.role === Roles.Doctor ? `&doctor_id=${admin.doctor_id}` : ""
        }`
      }
      config={{
        columns: [
          {
            label: "name",
            db_name: "name",
            gridOptions: { md: 4 },
            notEditable: true,
          },
          {
            label: "mobile number",
            db_name: "phone",
            gridOptions: { md: 4 },
            notEditable: true,
          },
          {
            label: "email",
            db_name: "email",
            notEditable: true,
            gridOptions: { md: 4 },
          },
          {
            label: "mode",
            db_name: "mode",
            transformFunc: (data) => (data ? "online" : "offline"),
           // transformFunc: (data) => (data ? "online" : "online"),
            gridOptions: { md: 4 },
            inputOptions: {
              type: "select",
              //options: [true, false],
              options: [true],
              // optionValueTransformer: (option) => (option ? "true" : "false"),
              // optionTransformer: (option) => (option ? "online" : "offline"),
              optionValueTransformer: (option) => (option ? "true" : "true"),
              optionTransformer: (option) => (option ? "online" : "online"),
            },
          },
          {
            label: "center",
            db_name: "clinic_name",
            gridOptions: { md: 4 },
            shouldBeRendered: (values) => String(values.mode) === "false",
            inputOptions: {
              type: "select",
              optionsFetchAPI: API("clinic").get("active"),
              optionTransformer: (option) => option.name,
              optionValueTransformer: (option) => option.name,
            },
          },
          {
            label: "doctor name",
            db_name: "doc_name",
            transformFuncFromRow: (row) =>
              Boolean(row.mode) ? row.online_doc_name : row.doc_name,
            gridOptions: { md: 4 },
            inputOptions: {
              type: "select",
              optionsFetchAPI: API("doctor").get(),
              optionTransformer: (option) => option.name,
              optionValueTransformer: (option) => option.doctor_id,
            },
          },
          {
            label: "package",
            db_name: "package_name",
            gridOptions: { md: 4 },
            inputOptions: {
              type: "select",
              optionsFetchAPI: API("package").get(),
              optionTransformer: (option) => option.title,
              optionValueTransformer: (option) => option.package_id,
            },
          },
          {
            label: "date",
            db_name: "date",
            transformFunc: (data) => moment(data).format("LL"),
            gridOptions: { md: 4 },
            defaultValue: moment().format("YYYY-MM-DD"),
            inputOptions: {
              type: "date",
            },
          },
          {
            label: "time (Online)",
            db_name: "s_time",
            shouldBeRendered: (values) => String(values.mode) === "true",
            transformFuncFromRow: (row) =>
              row.time ? "-" : moment(row.s_time, "HH:mm:ss").format("LT"),
            gridOptions: { md: 4 },
            inputOptions: {
              type: "select",
              optionDependencies: ["date", "doc_name", "mode"],
              optionsFetchAPI: API("appointment").post.JSON(
                "get-timeslots/for-admin"
              ),
              optionFetchRequestBodyTransformer: (values) => ({
                date: values.date,
                doctor_id: values.doc_name,
                mode: values.mode === "true",
              }),
              optionTransformer: (option) =>
                `${moment(option.s_time, "HH:mm:ss").format("LT")} - ${moment(
                  option.e_time,
                  "HH:mm:ss"
                ).format("LT")}`,
              optionValueTransformer: (option) => option.dt_id,
            },
          },
          {
            label: "time (Offline)",
            db_name: "time",
            transformFuncFromRow: (row) =>
              row.time ? moment(row.time, "HH:mm").format("LT") : "-",
            shouldBeRendered: (values) => String(values.mode) === "false",
            gridOptions: { md: 4 },
            defaultValue: moment().format("LT"),
            inputOptions: {
              type: "time",
            },
          },
          {
            label: "Consultancy type",
            db_name: "treatement_type_id",
            gridOptions: { md: 4 },
            transformFuncFromRow: (row) => row.treatment_type,
            inputOptions: {
              type: "select",
              optionDependencies: ["doc_name"],
              getOptionsFetchAPI: (values) =>
                API("treatment-type").get("by-doctor-id/" + values.doc_name),
              optionTransformer: (option) => option.name,
              optionValueTransformer: (option) => option.treatement_type_id,
            },
          },
          {
            label: "patient information",
            db_name: "patient_information",
            children: [
              {
                label: "name",
                db_name: "name",
                gridOptions: { md: 4 },
              },
              {
                label: "phone",
                db_name: "phone",
                gridOptions: { md: 4 },
              },
              {
                label: "email",
                db_name: "email",
                gridOptions: { md: 4 },
              },
              {
                label: "age",
                db_name: "age",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "number",
                },
              },
              {
                label: "gender",
                db_name: "gender",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "select",
                  options: ["male", "female", "other"],
                },
              },
              {
                label: "Associated user",
                db_name: "u_id",
                gridOptions: { md: 4 },
                inputOptions: {
                  type: "select",
                  optionsFetchAPI: API("user").get(),
                  optionTransformer: (option) =>
                    `${option.u_name ?? "NA"} - ${option.u_phone}`,
                  optionValueTransformer: (option) => option.u_id,
                },
              },
            ],
          },
          {
            label: "patient address",
            db_name: "patient_address",
            children: [
              {
                label: "alternate phone",
                db_name: "alt_phone",
                gridOptions: { md: 4 },
              },
              {
                label: "street",
                db_name: "street",
                gridOptions: { md: 4 },
              },
              {
                label: "landmark",
                db_name: "landmark",
                gridOptions: { md: 4 },
              },
              {
                label: "city",
                db_name: "city",
                gridOptions: { md: 4 },
              },
              {
                label: "state",
                db_name: "state",
                gridOptions: { md: 4 },
              },
              {
                label: "pincode",
                db_name: "pincode",
                gridOptions: { md: 4 },
              },
            ],
          },
        ],
        formikProps: { validationSchema },
      }}
      renderExtraActions={renderExtraActionButtons}
    />:
    <>
    <Row>
    <Col md={6}>
    <input name="o_mobile" placeholder="Enter patient mobile number" id="o_mobile" class="form-control" value={o_mobile} onChange={(event)=>seto_mobile(event.target.value)}/>
    </Col>
    <Col md={2}>
    <Button
    title={"Edit "}
    size="sm"
    variant={Offline?"primary":"outline-primary"}
    className="action-btn"
    style={{margin:'5px'}}
    onClick={()=>{setOffline(false);setTimeout(()=>{setOffline(true)},100)}}
  >Search</Button>
    </Col>
    </Row> 
   <br></br>
    </>
    }
    {Offline?
      <CRUDTable
      // pending={pending}
      API={API("crm/appointment_details?mobile_no="+o_mobile)}
      tableName="Offline Appointments"
      idExtractor={(data) => data.apt_id}
      modalSize="xl"
      hideEditButton
      hideDeleteButton
      hideAddButton
      isStaticLoad={true}
      getURLTransformer={(activePage) =>
        `?pageNum=${activePage}${
          admin.role === Roles.Doctor ? `&doctor_id=${admin.doctor_id}` : ""
        }`
      }
      config={{
        modalInitialValues: {
          treatments: [{ name: "" }],
          tests: [""],
          doctor_id: admin?.doctor_id,
          prescription_creation_method: "manual",
        },
        columns: [
          {
            label: "Consultant",
            db_name: "Doctor",
            gridOptions: { md: 6 },
            // transformFuncFromRow: (row) => row.doc_name,
            // inputOptions: {
            //   type: "select",
            //   optionsFetchAPI: API("doctor").get(),
            //   optionTransformer: (option) => option.name,
            //   optionValueTransformer: (option) => option.doctor_id,
            //   optionDisbaledStateExtractor: (option) => !option.addr_id,
            // },
          },
          {
            label: "patient",
            db_name: "Patient_Name",
            gridOptions: { md: 6 },
            // transformFuncFromRow: (row) => row.u_name ?? row.patient_name,
            // inputOptions: {
            //   type: "select",
            //   optionsFetchAPI: API("patient").get(),
            //   optionTransformer: (option) =>
            //     `${option.name ?? "NA"} - ${option.phone}`,
            //   optionValueTransformer: (option) => option.patient_id,
            //   optionDisbaledStateExtractor: (option) => !option.addr_id,
            // },
          },
          // {
          //   label: "Sr. Consultant",
          //   db_name: "srconsultant",
          //   gridOptions: { md: 6 },
          // },
          {
            label: "Appointment date",
            db_name: "Appo_Date",
            transformFunc: (data) => moment(data).format("LL"),
            gridOptions: { md: 6 },
            defaultValue: moment().format("YYYY-MM-DD"),
            inputOptions: {
              type: "date",
            },
          },
          {
            label: "Consultancy Type",
            db_name: "Consultancy_Type",
            gridOptions: { md: 6 },
            // inputOptions: {
            //   type: "multi-text",
            // },
          },
          {
            label: "Center_Name",
            db_name: "Cost_Cen_Name",
            notEditable: true,
            // transformFunc: (data) => moment(data).format("LL"),
            // inputOptions: {
            //   type: "date",
            // },
          },
          // {
          //   label: "attachments",
          //   db_name: "attachments",
          //   children: [
          //     {
          //       label: "image 1",
          //       db_name: "0",
          //       gridOptions: { md: 4 },
          //       inputOptions: {
          //         type: "file",
          //       },
          //     },
          //     {
          //       label: "image 2",
          //       db_name: "1",
          //       gridOptions: { md: 4 },
          //       inputOptions: {
          //         type: "file",
          //       },
          //     },
          //     {
          //       label: "image 3",
          //       db_name: "2",
          //       gridOptions: { md: 4 },
          //       inputOptions: {
          //         type: "file",
          //       },
          //     },
          //     {
          //       label: "image 4",
          //       db_name: "3",
          //       gridOptions: { md: 4 },
          //       inputOptions: {
          //         type: "file",
          //       },
          //     },
          //   ],
          // },
        ],
        formikProps: { validationSchema },
      }}
      renderExtraActions={renderExtraActionButtons}
    />:<></>}
    </div>
   
    
    </>
    
  );
};

export default Appointments;
