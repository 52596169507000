import React from "react";
import * as Yup from "yup";
import API from "../../../../API";
import CRUDTable from "../../../../Shared/CRUDTable";
import GetTimeslots from "./Components/GetTimeslots";
import * as validationSchemas from "../../../../validationSchemas";

const validationSchema = Yup.object({
  photo: Yup.mixed(),
  name: validationSchemas.full_name.label("Full name"),
  specialization: validationSchemas.readableText.required(),
  qualification: validationSchemas.readableText.required(),
  phone: validationSchemas.phone,
  email: validationSchemas.email,
  online_consultation_fee: validationSchemas.positiveNumber.label(
    "Online consultation fee"
  ),
  reg_no: validationSchemas.readableText.label("Registration number"),
  signature: Yup.mixed(),
  address: Yup.object({
    alt_phone: validationSchemas.phone.label("Alternate phone number"),
    street: validationSchemas.address
      .label("Street/Apartment/House no.")
      .required(),
    landmark: validationSchemas.readableText.label("landmark").required(),
    city: validationSchemas.city.label("city").required(),
    state: validationSchemas.state.label("state").required(),
    pincode: validationSchemas.zip.label("pincode").required(),
  }),
});

const Doctors = () => {
  return (
    <CRUDTable
      API={API("doctor")}
      idExtractor={(data) => data.doctor_id}
      modalSize="xl"
      tableName="Doctor"
      postURL="from-admin"
      putURL="from-admin"
      renderExtraStuffWithinModal={({ mode }) => (
        <GetTimeslots name="timeslots" formMode={mode} />
      )}
      hasFormData
      config={{
        extraFieldsToInitialize: [
          {
            db_name: "timeslots",
          },
        ],
        modalInitialValues: { timeslots: [], treatment_types: [{}] },
        columns: [
          {
            label: "photo",
            db_name: "photo",
            transformFunc: (image) => (
              <img src={image} width="90px" style={{ objectFit: "contain" }} />
            ),
            gridOptions: { md: 6 },
            inputOptions: { type: "file", formText: "Photo of the doctor" },
          },
          {
            label: "name",
            db_name: "name",
            gridOptions: { md: 6 },
            inputOptions: { formText: "Full name of the doctor" },
          },
          {
            label: "specialization",
            db_name: "specialization",
            gridOptions: { md: 6 },
          },
          {
            label: "qualification",
            db_name: "qualification",
            gridOptions: { md: 6 },
          },
          { label: "phone", db_name: "phone", gridOptions: { md: 6 } },
          { label: "email", db_name: "email", gridOptions: { md: 6 } },
          {
            label: "online consultancy fee",
            db_name: "online_consultation_fee",
            gridOptions: { md: 6 },
          },
          {
            label: "Registration number",
            db_name: "reg_no",
            gridOptions: { md: 6 },
          },
          {
            label: "signature",
            db_name: "signature",
            transformFunc: (image) => (
              <img src={image} width="90px" style={{ objectFit: "contain" }} />
            ),
            gridOptions: { md: 6 },
            inputOptions: {
              type: "file",
              formText: "Make sure the signature is horizontal in the image",
            },
          },
          {
            label: "Provided treatments",
            db_name: "treatment_types",
            hideFromTable: true,
            gridOptions: { md: 6 },
            inputOptions: {
              type: "multi-select",
              optionsFetchAPI: API("treatment-type").get(),
              optionTransformer: (option) => option.name,
              optionValueTransformer: (option) => option.treatement_type_id,
              formText: "Treatments provided by the doctor",
            },
          },
          {
            label: "address",
            db_name: "address",
            transformFunc: ({ street, landmark, city, state, pincode }) => (
              <div>
                <p className="mb-0">
                  {street}, {landmark}
                </p>
                <p className="mb-0">
                  {city}, {state}, {pincode}
                </p>
              </div>
            ),
            children: [
              {
                label: "Street/Apartment/House no.",
                db_name: "street",
                inputOptions: {
                  type: "textarea",
                },
              },
              {
                label: "alternate phone",
                db_name: "alt_phone",
                gridOptions: { md: 6 },
              },
              {
                label: "landmark",
                db_name: "landmark",
                gridOptions: { md: 6 },
              },
              {
                label: "city",
                db_name: "city",
                gridOptions: { md: 4 },
              },
              {
                label: "state",
                db_name: "state",
                gridOptions: { md: 4 },
              },
              {
                label: "pincode",
                db_name: "pincode",
                gridOptions: { md: 4 },
              },
            ],
          },
        ],
        formikProps: { validationSchema },
      }}
    />
  );
};

export default Doctors;
