/* eslint-disable react/prop-types */
import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { randomString, showNotification } from "../../utils";
import ButtonWithLoading from "./ButtonWithLoading";
import DataForm from "./DataFrom";

const fieldToInitialValue = (obj, data, mode, field, modalInitialValue) => {
  const intialValue =
    mode === "edit"
      ? field.formikInitailValueTransformFunc?.(data[field.db_name]) ??
        data[field.db_name] ??
        ""
      : field.defaultValue ?? "";

  obj[field.db_name] =
    modalInitialValue && typeof modalInitialValue !== typeof intialValue
      ? modalInitialValue
      : intialValue;
};

const ActionButton = ({
  tableName,
  modalInitialValues,
  columns,
  data,
  API,
  btn,
  refetch,
  mode,
  modalSize,
  formikProps,
  renderExtraStuffWithinModal,
  renderExtraStuffBerforeContentWithinModal,
  extraFieldsToInitialize = [],
}) => {
  const [open, setOpen] = useState(false);

  const initialValues = useMemo(() => {
    const obj = {};
    if (data)
      [...columns, ...extraFieldsToInitialize].forEach((field) => {
        if (field.notEditable) return;
        if (field.children) {
          obj[field.db_name] = {};
          field.children.forEach((child) => {
            fieldToInitialValue(
              obj[field.db_name],
              data[field.db_name],
              mode,
              child
            );
          });
        } else
          fieldToInitialValue(
            obj,
            data,
            mode,
            field,
            modalInitialValues?.[field.db_name]
          );
      });
    return obj;
  }, [data]);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    API(values)
      .then(() => {
        setSubmitting(false);
        refetch();
        showNotification({
          msg: `${tableName} ${mode === "edit" ? "updated" : "added"}`,
        });
        setOpen(false);
      })
      .catch((err) => {
        setSubmitting(false);
        showNotification({
          msg:
            err.message ??
            `Error ${
              mode === "edit" ? "Update" : "Add"
            }ing ${tableName.toLowerCase()}`,
          type: "danger",
        });
      });
  };

  const id = useMemo(() => randomString(), []);

  const actionButton = useMemo(
    () =>
      btn({
        onClick: () => {
          setOpen(true);
        },
      }),
    []
  );

  return (
    <>
      {actionButton}
      <Modal
        id={id}
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby={`${mode}-modal-${tableName}-${id}`}
        centered
        size={modalSize ?? "lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title id={`${mode}-modal-${tableName}-${id}`}>
            {mode.capitalize()} {tableName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataForm
            {...{
              formikProps,
              initialValues:
                mode === "edit"
                  ? { ...modalInitialValues, ...initialValues }
                  : { ...initialValues, ...modalInitialValues },
              handleSubmit,
              columns,
              renderExtraStuffWithinModal,
              renderExtraStuffBerforeContentWithinModal,
              mode,
            }}
            button={
              <ButtonWithLoading className="float-end mt-2">
                {mode === "edit" ? "Update" : "Add"}
              </ButtonWithLoading>
            }
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActionButton;
