import { createAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import AuthReducer, { Auth } from "./Slices/authSlice";
import NotificationReducer, { Notification } from "./Slices/notificationSlice";
import VideoCallReducer, { VideoCall } from "./Slices/videoCallSlice";

const resetStore = createAction("store/reset");

export const Actions = {
  Notification,
  Auth,
  VideoCall,
  Misc: {
    resetStore,
  },
};

export default function createRootReducer() {
  const rootReducer = combineReducers({
    NotificationReducer,
    AuthReducer,
    VideoCallReducer,
  });
  return (state, action) => {
    if (action.type === resetStore.type) return rootReducer(undefined, action);
    else return rootReducer(state, action);
  };
}
