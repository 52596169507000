import { useFormikContext } from "formik";
import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import FieldWithErrorMsg from "../../../../../Shared/FieldWithErrorMsg";
import GetTreatments from "./GetTreatments";

const FormOrPhoto = ({ photoName, formName }) => {
  const [selectedMethod, setSelectedMethod] = useState("manual");
  const { setFieldValue } = useFormikContext();

  const handleMethodChange = (e) => {
    setSelectedMethod(e.target.value);
    setFieldValue("prescription_creation_method", e.target.value);
    if (e.target.value === "manual") setFieldValue(photoName, undefined);
    else setFieldValue(formName, [{ name: "" }]);
  };

  return (
    <>
      <div className="mt-3">
        <h5 className="mb-2 pb-2 border-bottom border-1 d-flex justify-content-between align-items-center">
          Select prescription creation method
        </h5>

        <Form.Check
          type="radio"
          name="prescription_creation_method"
          value="manual"
          label="Through form"
          checked={selectedMethod === "manual"}
          onChange={handleMethodChange}
        />
        <Form.Check
          type="radio"
          name="prescription_creation_method"
          value="file"
          label="Upload file"
          checked={selectedMethod === "file"}
          onChange={handleMethodChange}
        />
      </div>

      {selectedMethod === "file" ? (
        <Col xs="auto" className="mt-2">
          <FieldWithErrorMsg
            type="file"
            label="Upload prescription file"
            name={photoName}
            required
          />
        </Col>
      ) : (
        <GetTreatments name={formName} />
      )}
    </>
  );
};

export default FormOrPhoto;
