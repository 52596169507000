import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  retrieveFromLocalStorage,
  saveInLocalStorage,
  showNotification,
} from "../../../utils";
import API from "../../API";
import { Actions } from "../rootReducer";

const login = createAsyncThunk(
  "auth/login",
  async (
    { values, setSubmitting, from, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await API("auth/admin", false).post.JSON("login")(values);
      saveInLocalStorage(data);
      dispatch(Actions.Auth.setData(data));
      setSubmitting(false);
      navigate(from || "/dashboard/view");
      showNotification({ msg: "You are now loggedin!" });
      return data;
    } catch (error) {
      switch (Number(error.message)) {
        case 403:
          showNotification({ msg: "Incorrect password!", type: "danger" });
          break;
        default:
          showNotification({ msg: "Something went wrong!", type: "danger" });
      }
      throw rejectWithValue(error.message);
    }
  }
);

const signup = createAsyncThunk(
  "auth/signup",
  async (
    { values, setSubmitting, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await API("auth", false).post.JSON("signup")(values);
      showNotification({ msg: "Signup successful!" });
      dispatch(login({ values, setSubmitting, from: "dashboard" }));
    } catch (error) {
      switch (Number(error.message)) {
        case 409:
          showNotification({
            msg: "Admin already exists",
            type: "danger",
          });
          navigate("/login");
          break;
        default:
          showNotification({ msg: "Something went wrong!", type: "danger" });
      }
      throw rejectWithValue(error.message);
    }
  }
);

const savedData = retrieveFromLocalStorage();

const { reducer: AuthReducer, actions } = createSlice({
  name: "auth",
  initialState: {
    token: savedData?.token,
    data: savedData?.data,
    pending: false,
    error: false,
  },
  reducers: {
    setData: (state, { payload }) => {
      state.pending = false;
      state.token = payload.token;
      state.data = payload.data;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.pending = true;
    },
    [login.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const Auth = {
  ...actions,
  login,
  signup,
};
export default AuthReducer;
