import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { io } from "socket.io-client";
import Peer from "peerjs";
import { MEET_API_URL, PeerServerConfig } from "../../../constants";
import { store } from "../../..";
import { showNotification } from "../../../utils";

let socket = io(MEET_API_URL, { forceNew: true });
let peerServer = new Peer(undefined, PeerServerConfig);

socket.on("connection", () => console.log("Socket connected"));
peerServer.on("error", function (err) {
  console.log(err);
});
peerServer.on("open", (peerId) => {
  store.dispatch(VideoCall.setMyPeerId(peerId));
});
socket.on("store-id", (id) => store.dispatch(VideoCall.setMySocketId(id)));

const joinRoom = createAsyncThunk(
  "video-call/join",
  async ({ stream: myStream, room, callback }, { dispatch, getState }) => {
    const get = (what) => getState().VideoCallReducer[what];

    dispatch(VideoCall.setMyStream(myStream));
    dispatch(VideoCall.setRoomId(room));

    socket.on("someone-joined", (newParticipant) => {
      dispatch(VideoCall.addPeer(newParticipant));

      showNotification({
        msg: `${newParticipant.name} has joined`,
        type: "info",
      });

      socket.emit("introducing-myself", {
        to: newParticipant.socketId,
      });
    });
    socket.on("someone-introducing", (thatSomeone) => {
      dispatch(VideoCall.addPeer(thatSomeone));

      socket.emit("acknowledging-introduction", { to: thatSomeone.socketId });
    });
    socket.on("someone-acknowledged-introduction", (thatSomeone) => {
      const mediaConn = peerServer.call(thatSomeone.peerId, myStream);
      mediaConn.on("stream", (stream) => {
        dispatch(
          VideoCall.addStreamToAPeer({
            peerId: thatSomeone.peerId,
            stream,
          })
        );
      });
    });
    socket.on("someone-left", ({ peerId, name }) => {
      showNotification({ msg: `${name} has left`, type: "info" });
      dispatch(VideoCall.removePeer(peerId));
    });
    socket.on("someone-toggled-their-media", (toggleData) => {
      dispatch(VideoCall.changePeerMediaEnabled(toggleData));
    });

    peerServer.on("call", (call) => {
      call.answer(myStream);
      call.on("stream", (stream) => {
        dispatch(VideoCall.addStreamToAPeer({ peerId: call.peer, stream }));
      });
    });

    socket.emit("join", {
      room,
      peerId: get("myPeerId"),
      socketId: get("mySocketId"),
      name: getState().AuthReducer.data?.name,
    });

    callback();
  }
);

const leaveRoom = createAsyncThunk(
  "video-call/leave",
  async ({ callback }, { getState }) => {
    const get = (what) => getState().VideoCallReducer[what];

    socket.disconnect();
    peerServer.destroy();

    get("myStream")
      .getTracks()
      ?.forEach(function (track) {
        console.log(track);
        if (track.readyState === "live") {
          track.stop();
        }
      });

    callback();
  }
);

const toggleMedia = createAsyncThunk(
  "video-call/toggle/mic",
  ({ value, kind }, { getState }) => {
    const get = (what) => getState().VideoCallReducer[what];
    socket.emit("toggle-media", {
      value,
      peerId: get("myPeerId"),
      room: get("roomId"),
      kind,
    });
    return { value, kind };
  }
);

const { reducer: VideoCallReducer, actions } = createSlice({
  name: "video-call",
  initialState: {
    mySocketId: null,
    myPeerId: null,
    myStream: null,
    peers: new Map(),
    roomId: null,
  },
  reducers: {
    setMySocketId: (state, action) => {
      state.mySocketId = action.payload;
    },
    addPeer: (state, action) => {
      // state.peers = new Map(
      state.peers.set(action.payload.peerId, {
        ...action.payload,
        audio: true,
        video: true,
      });
      // );
    },
    setMyStream: (state, action) => {
      state.myStream = action.payload;
    },
    setMyPeerId: (state, action) => {
      state.myPeerId = action.payload;
    },
    addStreamToAPeer: (state, { payload: { peerId, stream } }) => {
      state.peers.set(peerId, { ...(state.peers.get(peerId) ?? {}), stream });
    },
    removePeer: (state, action) => {
      state.peers.delete(action.payload);
    },
    setRoomId: (state, action) => {
      state.roomId = action.payload;
    },
    changePeerMediaEnabled: (state, { payload: { kind, value, peerId } }) => {
      state.peers.set(peerId, { ...state.peers.get(peerId), [kind]: value });
    },
  },
  extraReducers: {
    [toggleMedia.fulfilled]: (state, { payload: { value, kind } }) => {
      state.myStream.getTracks()?.forEach(function (track) {
        console.log(track);
        if (track.kind === kind) {
          track.enabled = value;
        }
      });
    },
    [leaveRoom.fulfilled]: (state) => {
      state.roomId = null;
      state.myStream = null;
      state.peers.clear();
    },
  },
});

export const VideoCall = { joinRoom, leaveRoom, toggleMedia, ...actions };
export default VideoCallReducer;
