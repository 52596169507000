import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const DeleteButton = ({ handleDelete, what, size }) => {
  const [open, setOpen] = useState();
  return (
    <div>
      <Button
        title="Delete"
        size={size ?? "sm"}
        variant="danger"
        className="action-btn"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby="edit-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="edit-modal" as="p">
            Are you sure you want to delete {what}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="d-flex justify-content-end">
          <Button onClick={() => setOpen(false)} variant="light">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDelete();
              setOpen(false);
            }}
            variant="danger"
          >
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteButton;
