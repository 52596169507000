import React, { useMemo } from "react";
import "./App.scss";
import { Route, Routes, Navigate } from "react-router-dom";
import routes from "./routes";
import AuthRequired from "./Shared/AuthRequired";
import { useSelector } from "react-redux";
import { isAllowed } from "../utils";

const App = () => {
  const admin = useSelector(({ AuthReducer }) => AuthReducer.data ?? {});

  const defaultRoute = useMemo(() => {
    const [{ path, views }] = Object.values(routes);
    let baseUrl = path,
      childUrl = "";
    if (views)
      childUrl = Object.values(views).filter(
        (x) => !admin.role || isAllowed(x.roles, admin.role)
      )[0].path;

    return `/${baseUrl}/${childUrl}`;
  }, [admin]);

  return (
    <Routes>
      {makeRoutes(routes, admin.role)}
      <Route path="*" element={<Navigate to={defaultRoute} replace={true} />} />
    </Routes>
  );
};

function makeRoutes(routes, role) {
  const renderedRoutes = [];
  let key = 0;

  /**
   * loop over the route object
   * object keys are paths
   */
  for (const module in routes) {
    if (Object.hasOwnProperty.call(routes, module)) {
      let { element, views, authRequired, index, path, roles } = routes[module];

      element = authRequired ? <AuthRequired>{element}</AuthRequired> : element;

      if (isAllowed(roles, role))
        renderedRoutes.push(
          views ? (
            <Route {...{ element, path, index }} key={key++}>
              {Object.values(views).map(
                ({ element, path, role }) =>
                  isAllowed(roles, role) && (
                    <>
                      <Route {...{ element, path }} key={key++} />
                    </>
                  )
              )}
            </Route>
          ) : (
            <Route {...{ element, path }} key={key++} />
          )
        );
    }
  }

  return renderedRoutes;
}

export default App;
